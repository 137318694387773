import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification, Drawer } from "antd"; 
import { AuthTokenType, UserProps, WasteLogprops } from "../utils/types";
import { getAuthToken, getwastelog } from "../utils/functions";
import axios from "axios";
import ContentLayout from "../components/ContentLayout";
import { useGetUsers, useGetwastelog } from "../utils/hooks";
import { WastelogUrl } from "../utils/network";
import AddWasteLogForm from "../components/AddWasteLogform";
import dayjs from "dayjs";
import Wastesummary from "../components/WasteReport";
import edit1 from '../assets/edit.png'; // Import the logo image
import action from '../assets/action.png'; // Import the logo image
import view from '../assets/view.png'; // Import the logo image
import liquor from '../assets/liquor.png'; // Import the logo image
import wastedby from '../assets/wastedby.png'; // Import the logo image
import overflow from '../assets/overflow.png'; // Import the logo image
import reason from '../assets/reason.png'; // Import the logo image

import user from '../assets/users.png'; // Import the logo image
import amount from '../assets/finance.png'; // Import the logo image
import date from '../assets/date.png'; // Import the logo image


const WasteLogs: FC = () => {
  const [wasteLogs, setWasteLogs] = useState<WasteLogprops[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingWasteLog, setEditingWasteLog] = useState<WasteLogprops | null>(null);
  const [users, setUsers] = useState<UserProps[]>([]); // For reported by dropdown
  const [showReportDrawer, setShowReportDrawer] = useState<boolean>(false); // State for report drawer visibility

  // Fetch products and users
  useGetUsers(setUsers, setFetching);
  useGetwastelog(setWasteLogs, setFetching);

  useEffect(() => {
    if (editingWasteLog) {
      setDrawerVisible(true);
    }
  }, [editingWasteLog]);

  const handleEdit = (record: WasteLogprops) => {
    setEditingWasteLog(record);
  };

  const handleDelete = (wasteLogId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this waste log?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          await axios.delete(`${WastelogUrl}/${wasteLogId}/`, headers);
          getwastelog(setWasteLogs, setFetching);
        } catch (error) {
          notification.error({ message: "Delete Operation Error" });
        }
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const columns = [
    {       title: () => (
      <span>
        <img src={liquor} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
        SKU Name
      </span>
    ),
dataIndex: ["sku_code", "name"], key: ["sku_code", "name"] },
    {  title: () => (
      <span>
        <img src={date} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
        Date
      </span>
    ), dataIndex: "date", key: "date", render: (date: string) => dayjs(date).format("MM-DD-YYYY") },
    { title: () => (
      <span>
        <img src={reason} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
        Reason
      </span>
    ), dataIndex: "reason", key: "reason" },
    { title: () => (
      <span>
        <img src={wastedby} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
        Wasted By
      </span>
    ), dataIndex: "wasted_by", key: "wasted_by" },
    { title: () => (
      <span>
        <img src={user} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
        Reported by
      </span>
    ), dataIndex: "reported_by", key: "reported_by" },
    { title: () => (
      <span>
        <img src={overflow} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
        Quantity
      </span>
    ), dataIndex: "quantity", key: "quantity" },
    { title: () => (
      <span>
        <img src={amount} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
        Amount
      </span>
    ), dataIndex: "amount", key: "amount" },
    {
      title: () => (
        <span>
          <img src={action} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Action
        </span>
      ),
      key: "actions",
      render: (text: string, record: WasteLogprops) => (
        <span>
          <a onClick={() => handleEdit(record)}>          <img
            src={edit1}
            alt="delete"
            style={{ width: '20px', height: '20px', marginRight: '8px' }}
          />
</a>
        </span>
      ),
    },
  ];

  // Report button handler to show the drawer with Wastesummary
  const handleReportButtonClick = () => {
    setShowReportDrawer(true); // Open the drawer
  };

  return (
    <ContentLayout
      pageTitle="Waste Logs"
      buttontitle="Add Waste Log"
      setModalState={setDrawerVisible}
      dataSource={wasteLogs as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
      reportButton={handleReportButtonClick} // Pass the handler for the Report button
    >
      <AddWasteLogForm
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getwastelog(setWasteLogs, setFetching);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingWastelog={editingWasteLog}
        onCloseWithoutEditing={() => setEditingWasteLog(null)}
      />
      
      {/* Report Drawer without header */}
      <Drawer
        title={null} // No title in the drawer
        visible={showReportDrawer} 
        onClose={() => setShowReportDrawer(false)} 
        width={800}
        placement="right"
        destroyOnClose={true} // Destroy the content when closed
        headerStyle={{ display: 'none' }} // Remove the header entirely
        bodyStyle={{ padding: 0 }} // Remove any padding in the body of the drawer
      >
        <Wastesummary />
      </Drawer>
    </ContentLayout>
  );
};

export default WasteLogs;