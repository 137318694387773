import { useContext } from "react";
import { store } from "../utils/store";
import InventoryTable from "./InventoryTable";
import BudgetComparison from "../components/Dashboard/BudgetComparison";
import PurchaseReport from "../components/Dashboard/PurchaseReport";
import Pricemovements from "../components/Dashboard/Pricemovements";

function Home(){
  const{state}=useContext(store)
  return (
      <div>
          <div>
          </div>
              <br/>
              <div >
              <BudgetComparison/>
                <PurchaseReport/>
                <Pricemovements/>
              </div>
              <br/>
          </div>
  )
}
export default Home