import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification, Input, Select, DatePicker, TimePicker } from "antd";
import { AuthTokenType, InventoryCheckReminderProps, product, UserProps } from "../utils/types";
import { getAuthToken, getinventorycheck } from "../utils/functions";
import {useGetinventorycheck, useGetProductwithinventory, useGetUsers } from "../utils/hooks";
import axios from "axios";
import ContentLayoutnew from "../components/ContentLayoutnew";
import { inventorycheckUrl } from "../utils/network";
import dayjs from 'dayjs'; // Import dayjs

import delete1 from '../assets/delete.png'; // Import the logo image
import edit1 from '../assets/edit.png'; // Import the logo image
import action from '../assets/action.png'; // Import the logo image
import view from '../assets/view.png'; // Import the logo image
import liquor from '../assets/liquor.png'; // Import the logo image
import wastedby from '../assets/wastedby.png'; // Import the logo image
import overflow from '../assets/overflow.png'; // Import the logo image
import reason from '../assets/reason.png'; // Import the logo image
import save from '../assets/save.png'; // Import the logo image
import close1 from '../assets/close.png'; // Import the logo image

import user from '../assets/users.png'; // Import the logo image
import amount from '../assets/finance.png'; // Import the logo image
import date from '../assets/date.png'; // Import the logo image
import level1 from '../assets/type.png'; // Import the logo image
import frequency from '../assets/frequency.png'; // Import the logo image
import entity from '../assets/entity.png'; // Import the logo image
import time from '../assets/time.png'; // Import the logo image


const InventoryCheckReminders: FC = () => {
  const [inventoryCheckReminders, setInventoryCheckReminders] = useState<InventoryCheckReminderProps[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [newInventoryCheckReminder, setNewInventoryCheckReminder] = useState<any>({
    id: -1,
    sku_code: null,
    reminder_time: "",
    frequency: "Daily", // Default frequency
  });
  const [editingInventoryCheckReminder, setEditingInventoryCheckReminder] = useState<any | null>(null);
  const [products, setProducts] = useState<product[]>([]);

  useGetProductwithinventory(setProducts, setFetching);
  useGetinventorycheck(setInventoryCheckReminders, setFetching);

  const handleDelete = (recordId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this Inventory Check Reminder?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          await axios.delete(`${inventorycheckUrl}/${recordId}`, headers);
          getinventorycheck(setInventoryCheckReminders, setFetching);
          notification.success({ message: "Inventory Check Reminder deleted successfully!" });
        } catch (error) {
          notification.error({ message: "Failed to delete Inventory Check Reminder!" });
        }
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const handleDeleteSelected = async (ids: number[]) => {
    try {
      const headers = getAuthToken() as AuthTokenType;
      await Promise.all(ids.map((id) => axios.delete(`${inventorycheckUrl}/${id}`, headers)));
      getinventorycheck(setInventoryCheckReminders, setFetching);
      notification.success({ message: "Selected Inventory Check Reminders deleted successfully!" });
    } catch (error) {
      notification.error({ message: "Failed to delete selected Inventory Check Reminders!" });
    }
  };

  const handleAddRow = () => {
    setIsAdding(true);
    setNewInventoryCheckReminder({
      id: -1,
      sku_code: null,
      reminder_time: "",
      frequency: "Daily", // Default frequency
    });
  };

  const handleSaveNewInventoryCheckReminder = async () => {
    try {
      const headers = getAuthToken() as AuthTokenType;
      const response = await axios.post(inventorycheckUrl, {
        sku_code_id: newInventoryCheckReminder.sku_code,
        reminder_time: newInventoryCheckReminder.reminder_time,
        frequency: newInventoryCheckReminder.frequency,
      }, headers);

      setInventoryCheckReminders((prev) => [...prev, response.data]);
      setNewInventoryCheckReminder({
        id: -1,
        sku_code: null,
        reminder_time: "",
        frequency: "Daily",
      });
      setIsAdding(false);
      notification.success({ message: "Inventory Check Reminder added successfully!" });
    } catch (error) {
      notification.error({ message: "Failed to add Inventory Check Reminder!" });
    }
  };

  const handleEdit = (record: any) => {
    setEditingInventoryCheckReminder(record);
  };

  const handleSaveEdit = async (inventoryCheckReminder: any) => {
    try {
      const headers = getAuthToken() as AuthTokenType;
      await axios.put(`${inventorycheckUrl}/${inventoryCheckReminder.id}`, {
        ...inventoryCheckReminder,
        sku_code_id: inventoryCheckReminder.sku_code.id, // Send the sku_code id (foreign key)
      }, headers);

      getinventorycheck(setInventoryCheckReminders, setFetching);
      setEditingInventoryCheckReminder(null);
      notification.success({ message: "Inventory Check Reminder updated successfully!" });
    } catch (error) {
      notification.error({ message: "Failed to update Inventory Check Reminder!" });
    }
  };

  const columns = [
    {
      title: () => (
        <span>
          <img src={liquor} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          SKU Name
        </span>
      ),
      dataIndex: "sku_code",
      key: "sku_code",
      render: (sku_code: product, record: any) => {
        if (isAdding && record.id === -1) {
          return (
            <Select
              value={newInventoryCheckReminder.sku_code}
              onChange={(value) => setNewInventoryCheckReminder({ ...newInventoryCheckReminder, sku_code: value })}
              placeholder="Select SKU Code"
              style={{ width: "100%" }}
            >
              {products.map((product) => (
                <Select.Option key={product.id} value={product.id}>
                  {product.name}
                </Select.Option>
              ))}
            </Select>
          );
        }
        if (editingInventoryCheckReminder && editingInventoryCheckReminder.id === record.id) {
          return (
            <Select
              value={editingInventoryCheckReminder.sku_code.id} // Show SKU Code ID
              onChange={(value) =>
                setEditingInventoryCheckReminder({
                  ...editingInventoryCheckReminder,
                  sku_code: products.find((product) => product.id === value) || editingInventoryCheckReminder.sku_code,
                })
              }
              placeholder="Select SKU Code"
              style={{ width: "100%" }}
            >
              {products.map((product) => (
                <Select.Option key={product.id} value={product.id}>
                  {product.name}
                </Select.Option>
              ))}
            </Select>
          );
        }
        return sku_code.name; // Display SKU Code when not editing
      },
    },
    {
      title: () => (
        <span>
          <img src={time} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Reminder Time
        </span>
      ),
      dataIndex: "reminder_time",
      key: "reminder_time",
      render: (text: string, record: any) => {
        if (isAdding && record.id === -1) {
          return (
            <TimePicker
              value={newInventoryCheckReminder.reminder_time ? dayjs(newInventoryCheckReminder.reminder_time, 'HH:mm') : null}
              onChange={(time, timeString) => 
                setNewInventoryCheckReminder({ ...newInventoryCheckReminder, reminder_time: timeString })
              }
              format="HH:mm"
              placeholder="Select reminder time"
              style={{ width: '100%' }}
            />
          );
        }
        if (editingInventoryCheckReminder && editingInventoryCheckReminder.id === record.id) {
          return (
            <TimePicker
              value={editingInventoryCheckReminder.reminder_time ? dayjs(editingInventoryCheckReminder.reminder_time, 'HH:mm') : null}
              onChange={(time, timeString) => 
                setEditingInventoryCheckReminder({
                  ...editingInventoryCheckReminder,
                  reminder_time: timeString,
                })
              }
              format="HH:mm"
              placeholder="Select reminder time"
              style={{ width: '100%' }}
            />
          );
        }
        return text; // Display the reminder time in string format when not editing
      },
    },
    {
      title: () => (
        <span>
          <img src={frequency} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Frequency
        </span>
      ),
      dataIndex: "frequency",
      key: "frequency",
      render: (text: string, record: any) => {
        if (isAdding && record.id === -1) {
          return (
            <Select
              value={newInventoryCheckReminder.frequency}
              onChange={(value) => setNewInventoryCheckReminder({ ...newInventoryCheckReminder, frequency: value })}
              placeholder="Select Frequency"
              style={{ width: "100%" }}
            >
              {['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly'].map((frequency) => (
                <Select.Option key={frequency} value={frequency}>
                  {frequency}
                </Select.Option>
              ))}
            </Select>
          );
        }
        if (editingInventoryCheckReminder && editingInventoryCheckReminder.id === record.id) {
          return (
            <Select
              value={editingInventoryCheckReminder.frequency}
              onChange={(value) => setEditingInventoryCheckReminder({ ...editingInventoryCheckReminder, frequency: value })}
              placeholder="Select Frequency"
              style={{ width: "100%" }}
            >
              {['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly'].map((frequency) => (
                <Select.Option key={frequency} value={frequency}>
                  {frequency}
                </Select.Option>
              ))}
            </Select>
          );
        }
        return text;
      },
    },
    {
      title: () => (
        <span>
          <img src={action} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Actions
        </span>
      ),
      key: "actions",
      render: (text: string, record: any) => {
        if (isAdding && record.id === -1) {
          return (
            <span>
              <a onClick={handleSaveNewInventoryCheckReminder}>
                <img src={save} alt="Save" style={{ width: "20px", height: "20px", marginRight: "8px" }} />
              </a>
              <span> | </span>
              <a
                onClick={() => {
                  setIsAdding(false);
                  setNewInventoryCheckReminder({ sku_code: null, reminder_time: "", frequency: "Daily" });
                }}
              >
                <img src={close1} alt="Cancel" style={{ width: "20px", height: "20px", marginLeft: "8px" }} />
              </a>
            </span>
          );
        }
      
        if (editingInventoryCheckReminder && editingInventoryCheckReminder.id === record.id) {
          return (
            <span>
              <a onClick={() => handleSaveEdit(editingInventoryCheckReminder)}>
                <img src={save} alt="Save" style={{ width: "20px", height: "20px", marginRight: "8px" }} />
              </a>
              <span> | </span>
              <a onClick={() => setEditingInventoryCheckReminder(null)}>
                <img src={close1} alt="Cancel" style={{ width: "20px", height: "20px", marginLeft: "8px" }} />
              </a>
            </span>
          );
        }
      
        return (
          <span>
            <a onClick={() => handleEdit(record)}>
              <img src={edit1} alt="Edit" style={{ width: "20px", height: "20px", marginRight: "8px" }} />
            </a>
            <span> | </span>
            <a onClick={() => handleDelete(record.id)}>
              <img src={delete1} alt="Delete" style={{ width: "20px", height: "20px", marginLeft: "8px" }} />
            </a>
          </span>
        );
    }
    },
  ];

  const dataSource = isAdding
    ? [
        ...inventoryCheckReminders,
        {
          id: -1,
          sku_code: { id: newInventoryCheckReminder.sku_code, name: "" },
          reminder_time: newInventoryCheckReminder.reminder_time,
          frequency: newInventoryCheckReminder.frequency,
        },
      ]
    : inventoryCheckReminders;

  return (
    <ContentLayoutnew
      pageTitle="Cycle Count"
      buttontitle="Add Reminder"
      setModalState={() => {}}
      dataSource={dataSource as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
      onAddRow={handleAddRow}
      onDeleteSelected={handleDeleteSelected}
    />
  );
};

export default InventoryCheckReminders;