import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification } from "antd";
import { useGetLocation } from "../utils/hooks";
import { AuthTokenType, location } from "../utils/types";
import { getAuthToken, getLocation } from "../utils/functions";
import AddLocationForm from "../components/AddLocationForm";
import axios, { AxiosResponse } from "axios";
import { locationsUrl } from "../utils/network";
import ContentLayout from "../components/ContentLayout";
import entity from '../assets/entity.png'; // Import the logo image
import name from '../assets/name.png'; // Import the logo image
import map from '../assets/map.png'; // Import the logo image
import city from '../assets/city.png'; // Import the logo image
import state from '../assets/state.png'; // Import the logo image
import country from '../assets/country.png'; // Import the logo image
import action from '../assets/action.png'; // Import the logo image

import delete1 from '../assets/delete.png'; // Import the logo image
import edit1 from '../assets/edit.png'; // Import the logo image



const Locations: FC = () => {
  const columns = [

    {
      title: () => (
        <span>
          <img src={name} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Name
        </span>
      ),
      dataIndex: "name",
      key: "name",
    },
    {
      title: () => (
        <span>
          <img src={entity} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Type
        </span>
      ),
      dataIndex: "type",
      key: "type",
    },
    {
      title: () => (
        <span>
          <img src={map} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Address
        </span>
      ),
      dataIndex: "address",
      key: "address",
    },
    {
      title: () => (
        <span>
          <img src={city} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          City
        </span>
      ),
      dataIndex: "city",
      key: "city",
    },
    {
      title: () => (
        <span>
          <img src={state} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          State
        </span>
      ),
      dataIndex: "state",
      key: "state",
    },
    {
      title: () => (
        <span>
          <img src={country} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Country
        </span>
      ),
      dataIndex: "country",
      key: "country",
    },
    
      {
        title: () => (
          <span>
            <img src={action} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
            Actions
          </span>
        ),
        key: "actions",
      render: (text: string, record: location) => (
        <span>
          <a onClick={() => handleEdit(record)}><img src={edit1} alt="Save" style={{ width: "20px", height: "20px", marginRight: "8px" }} />
          </a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}><img src={delete1} alt="Save" style={{ width: "20px", height: "20px", marginRight: "8px" }} /></a>
        </span>
      ),
    },
  ];

  const [location, setLocation] = useState<location[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingLocation, setEditingLocation] = useState<location | null>(null);

  useGetLocation(setLocation, setFetching);

  useEffect(() => {
    // When editingLocation changes, open the modal
    if (editingLocation) {
      setDrawerVisible(true);
    }
  }, [editingLocation]);

  const handleEdit = (record: location) => {
    setEditingLocation(record);
  };

  const handleDelete = (locationId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this location?",
      onOk: async () => {
        try {
          let response: AxiosResponse;

          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a location
          response=await axios.delete(`${locationsUrl}/${locationId}/`, headers);

          // After successful deletion, fetch updated locations
          getLocation(setLocation, setFetching);
        } catch (error:any) {
          // Handle error if the deletion fails
          notification.error({
            message: error.response.data.error,
          });
        }
      },
      
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const onCloseWithoutEditing = () => {
    setEditingLocation(null); // Reset editingCategory when form is closed without editing
  };

  const onCreateLocation = () => {
    setDrawerVisible(true);
    setEditingLocation(null); // Reset editingLocation for new location
  };

  return (
    <ContentLayout
      pageTitle="Site Locations"
      buttontitle="Add Location"
      setModalState={setDrawerVisible}
      dataSource={location as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}

    >
      <AddLocationForm
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getLocation(setLocation, setFetching);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingLocation={editingLocation}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </ContentLayout>
  );
};

export default Locations;