import React, { FC, useEffect, useState } from 'react';
import { Button, Table, Modal, notification, Select, Input, Slider, InputNumber } from 'antd';
import { useGetOnhandIventory } from '../utils/hooks';
import { AuthTokenType, OnhandInventoryProps } from '../utils/types';
import { getAuthToken, getOnhandInventory, getproductinventory } from '../utils/functions';
import AddOnHandInventoryForm from '../components/AddOnHandInventoryForm';
import axios from 'axios';
import { onhandUrl } from '../utils/network';
import warehouse from '../assets/warehouse.png'; // Import the logo image
import delivery from '../assets/delivery.png'; // Import the logo image
import map from '../assets/map.png'; // Import the logo image
import liquor from '../assets/liquor.png'; // Import the logo image
import classification from '../assets/classification.png'; // Import the logo image
import market from '../assets/market-segment.png'; // Import the logo image
import minimum from '../assets/minimum.png'; // Import the logo image
import maximum from '../assets/maximum.png'; // Import the logo image
import overflow from '../assets/overflow.png'; // Import the logo image
import action from '../assets/action.png'; // Import the logo image
import delete1 from '../assets/delete.png'; // Import the logo image
import status1 from '../assets/status.png'; // Import the logo image
import reason from '../assets/reason.png'; // Import the logo image
import exchange from '../assets/exchange.png'; // Import the logo image
import add from '../assets/add.png'; // Import the logo image
import edit1 from '../assets/edit.png'; // Import the logo image
import close1 from '../assets/close.png'; // Import the logo image
import save from '../assets/save.png'; // Import the logo image
import search from '../assets/search.png'; // Import the logo image

const { Option } = Select;

const OnHandInventory: FC = () => {

  const [productinvVisible, setProductinvVisible] = useState<boolean>(false);
  const [productinv, setproductinv] = useState<any[]>([]);
  const [prodinvFetching, setprodinvFetching] = useState<boolean>(false);
  const [quickEditMode, setQuickEditMode] = useState<boolean>(false);
  const [onhandinventory, setOnHandInventory] = useState<OnhandInventoryProps[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingInventory, seteditingInventory] = useState<OnhandInventoryProps | null>(null);

  useGetOnhandIventory(setOnHandInventory, setFetching);

  const handleQuickEditSave = async () => {
    const headers = getAuthToken() as AuthTokenType;
  
    const updates = onhandinventory.map(item => {
      const updateData: any = {
        id: item.id,
        min: item.min,  // Always include min
        max: item.max   // Always include max
      };
    
      // Set changedunits to 0 if undefined
      updateData.changedunits = item.changedunits !== undefined ? item.changedunits : 0;
    
      // Set reason to 'NA' if undefined
      updateData.reason = item.reason !== undefined ? item.reason : 'NA';
    
      return updateData;
    });
       try {
      // Send each update sequentially
      for (const values of updates) {
        await axios.patch(`${onhandUrl}/${values.id}`, values, headers);
      }
  
      notification.success({
        message: 'Inventory Updates Pushed',
      });
      setQuickEditMode(false);
      getOnhandInventory(setOnHandInventory, setFetching);
    } catch (error) {
      notification.error({
        message: 'Quick Edit Save Error',
      });
    }
  };


  const handlenameClick = (productId: number,locationId:number) => {
   
    setProductinvVisible(true);
    getproductinventory(setproductinv,setprodinvFetching, productId,locationId);
  };


  const uniqueCategories = Array.from(new Set(onhandinventory.map(item => item.sku_code.category.parent.name)))
  .map(cat => ({ text: cat, value: cat }));

const uniqueSubCategories = Array.from(new Set(onhandinventory.map(item => item.sku_code.category.name)))
  .map(subcat => ({ text: subcat, value: subcat }));


  const columns = [
    {
      title: () => (
        <span>
          <img src={liquor} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          SKU Name
        </span>
      ),
      dataIndex: 'name',
      key: 'name',
      render: (name: number, record: OnhandInventoryProps) => (
        <a onClick={() => handlenameClick(record.productid, record.locationid)}>
          <span>{name}</span>
        </a>
      ),
    },
    
    {
      title: () => (
        <span>
          <img src={classification} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Category
        </span>
      ),
      dataIndex: 'cat',
      key: 'cat',
      filters: uniqueCategories,  // Filter options from data
      onFilter: (value:any, record:any) => record.cat === value, // Filtering logic
      render: (text: string) => <span>{text}</span>,
    },
    
    {
      title: () => (
        <span>
          <img src={market} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Sub Category
        </span>
      ),
      dataIndex: 'subcat',
      key: 'subcat',
      filters: uniqueSubCategories, // Filter options from data
      onFilter: (value:any, record:any) => record.subcat === value, // Filtering logic
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: () => (
        <span>
          <img src={map} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Location
        </span>
      ),
      dataIndex: 'storage',
      key: 'storage',
      render: (text: string) => <span>{text}</span>,
    },

    {
      title: () => (
        <span>
          <img src={status1} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Status
        </span>
      ),
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => {
        if (text === 'InStock') {
          return (
            <span>
              <img src={warehouse} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
              In Stock
            </span>
          );
        } else if (text === 'Pending') {
          return (
            <span>
              <img src={delivery} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
              Pending
            </span>
          );
        }
        return text; // Default case if status is neither 'InStock' nor 'Pending'
      },
    },
    {
      title: () => (
        <span>
          <img src={overflow} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          on Hand
        </span>
      ),
      dataIndex: 'totalunits',
      key: 'totalunits',
      render: (text: number, record: OnhandInventoryProps) => {
        const { min, max } = record; // Assuming `min` and `max` are available in the record
    
        let boxColor = '#52c41a'; // Default color for values between `min` and `max`
    
        if (text <= min) {
          boxColor = '#ff4d4f'; // Red color for values less than or equal to `min`
        } else if (text > max) {
          boxColor = '#faad14'; // Amber color for values greater than `max`
        }
    
        return (
          <div
            style={{
              width: '100px', // Box width
              height: '20px', // Box height
              backgroundColor: boxColor, // Set background color based on the condition
              color: '#ffffff', // White text color for contrast
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '12px',
              fontWeight: '300',
              lineHeight: '20px',
              borderRadius: '4px', // Optional: If you want slight rounded corners
            }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: () => (
        <span>
          <img src={minimum} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Min
        </span>
      ),
      dataIndex: 'min',
      key: 'min',
      render: (text: number, record: OnhandInventoryProps) =>
        quickEditMode ? (
          <InputNumber
            style={{ width: 100 }}
            value={text}
            onChange={(value) => {
              setOnHandInventory((prev) =>
                prev.map((item) =>
                  item.id === record.id ? { ...item, min: value || 0 } : item
                )
              );
            }}
          />
        ) : (
          <div
            style={{
              width: '40px', // Increased bar length
              height: '20px',
              backgroundColor: '#e0e0e0',
              borderRadius: '6px',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                width: `${(text / 1000) * 100}%`,
                height: '100%',
                backgroundColor: '#0a9',
                position: 'absolute',
                left: 0,
                top: 0,
                borderRadius: '6px',
              }}
            />
            <span
              style={{
                position: 'absolute',
                width: '100%',
                textAlign: 'center',
                fontSize: '12px',
                color: text / 1000 > 0.5 ? '#ffffff' : '#000000',
                fontWeight: '300',
                lineHeight: '20px',
              }}
            >
              {text}
            </span>
          </div>
        ),
    },
    {
      title: () => (
        <span>
          <img src={maximum} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Max
        </span>
      ),
      dataIndex: 'max',
      key: 'max',
      render: (text: number, record: OnhandInventoryProps) =>
        quickEditMode ? (
          <InputNumber
            style={{ width: 100 }}
            value={text}
            onChange={(value) => {
              setOnHandInventory((prev) =>
                prev.map((item) =>
                  item.id === record.id ? { ...item, max: value || 0 } : item
                )
              );
            }}
          />
        ) : (
          <div
            style={{
              width: '40px', // Increased bar length
              height: '20px',
              backgroundColor: '#e0e0e0',
              borderRadius: '6px',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                width: `${(text / 1000) * 100}%`,
                height: '100%',
                backgroundColor: '#0a9',
                position: 'absolute',
                left: 0,
                top: 0,
                borderRadius: '6px',
              }}
            />
            <span
              style={{
                position: 'absolute',
                width: '100%',
                textAlign: 'center',
                fontSize: '12px',
                color: text / 1000 > 0.5 ? '#ffffff' : '#000000',
                fontWeight: '300',
                lineHeight: '20px',
              }}
            >
              {text}
            </span>
          </div>
        ),
    },
    ...(quickEditMode
      ? [
          {
            title: () => (
              <span>
                <img src={exchange} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
                Changed Units
              </span>
            ),
                  dataIndex: 'changedunits',
            key: 'changedunits',
            render: (text: number, record: OnhandInventoryProps) => (
              <Input
              style={{ width: 100, }}   
              type="number"
                value={text}
                onChange={(e) => {
                  const newValue = parseFloat(e.target.value);
                  setOnHandInventory(prev => prev.map(item => 
                    item.id === record.id ? { ...item, changedunits: newValue } : item
                  ));
                }}
              />
            ),
          },
          {
            title: () => (
              <span>
                <img src={reason} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
                Reason
              </span>
            ),
            dataIndex: 'reason',            
            key: 'reason',
            render: (text: string, record: OnhandInventoryProps) => (
              <Select
              style={{ width: 100, }} 
                value={text}
                onChange={(value) => {
                  setOnHandInventory(prev => prev.map(item => 
                    item.id === record.id ? { ...item, reason: value } : item
                  ));
                }}
              >
                <Option value="Stock Received">Stock Received</Option>
                <Option value="Inventory Recount">Inventory Recount</Option>
                <Option value="Damage">Damage</Option>
                <Option value="Theft">Theft</Option>
                <Option value="Loss">Loss</Option>
                <Option value="Restock Return">Restock Return</Option>
              </Select>
            ),
          }
        ]
      : []
    ),
    {
      title: () => (
        <span>
          <img src={action} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Action
        </span>
      ),
      key: "actions",
      render: (text: string, record: OnhandInventoryProps) => (
        <span>
        <a onClick={() => handleDelete(record.id)}>
          <img
            src={delete1}
            alt="delete"
            style={{ width: '20px', height: '20px', marginRight: '8px' }}
          />
        </a>
      </span>      ),
    },


  ];


  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const onSuccessAddOnHandInventory = () => {
    setDrawerVisible(false);
    getOnhandInventory(setOnHandInventory, setFetching); // Refresh on-hand inventory list
  };



  useEffect(() => {
    // When editingCategory changes, open the modal
    if (editingInventory) {
      setDrawerVisible(true);
    }
  }, [editingInventory]);

  const onCloseWithoutEditing = () => {
    seteditingInventory(null); // Reset editingCategory when form is closed without editing
  };

  const handleEdit = (record: OnhandInventoryProps) => {
    seteditingInventory(record);
  };
  const handleDelete = (inventoryId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this category?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a category
          await axios.delete(`${onhandUrl}/${inventoryId}`, headers);
  
          // After successful deletion, fetch updated categories
          getOnhandInventory(setOnHandInventory, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: "Delete Operation Error",
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: "Yes",
      cancelText: "No",
    });
  };



  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = onhandinventory.filter((record) =>
    Object.values(record).some((value) =>
      value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );


  return (
    <>
      <div className="card">
      <div className="borderline">

        <div className="cardHeader">
          <h2 className="headContent">Manage Items</h2>
          </div>
          <div className="rightContent">
            <Input
              className='customInput'
              placeholder="Search..."
              onChange={handleSearch}
              prefix={<img src={search} alt="search" style={{ width: '20px', height: '20px' }} />} 
              style={{ marginRight: '5px', height: "40px", width: "200px", }}
            />
                         <Button className='bbutton' onClick={() => setDrawerVisible(true)}>
                         <img src={add} alt="add icon" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
                          Add Item</Button>

            <Button
              className='bbutton'
              onClick={() => setQuickEditMode(!quickEditMode)}
              style={{ marginRight: '5px' }}
            >
{quickEditMode ? (
  <img src={close1} alt="close icon" style={{ width: '20px', height: '20px' }} />
) : (
  <img src={edit1} alt="edit icon" style={{ width: '20px', height: '20px' }} />
)}            </Button>
            {quickEditMode && (
              <Button
                className='bbutton'
                onClick={handleQuickEditSave}
                style={{ marginRight: '5px' }}
              >
  <img src={save} alt="edit icon" style={{ width: '20px', height: '20px' }} />
  </Button>
            )}
          </div>
        <Table
          className="custom-table"
          dataSource={filteredData}
          columns={columns}
          rowKey="id"
          loading={fetching}
        />
      </div>

      <Modal
        visible={productinvVisible}
        onCancel={() => {
          setProductinvVisible(false);
          setproductinv([]);
        }}
        footer={null}
      >
        {prodinvFetching ? (
          <p>Loading...</p>
        ) : (
          <Table
            className="custom-table"
            dataSource={productinv}
            columns={[
              {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                render: (dateString: string) => {
                  const date = new Date(dateString);
                  const year = date.getFullYear();
                  const month = String(date.getMonth() + 1).padStart(2, '0');
                  const day = String(date.getDate()).padStart(2, '0');
                  return `${year}-${month}-${day}`;
                },
              },
              {
                title: 'Starting Inventory',
                dataIndex: 'previous_value',
                key: 'previous_value',
              },
              {
                title: 'Ending Inventory',
                dataIndex: 'inventory_level',
                key: 'inventory_level',
              },
              {
                title: 'Reason',
                dataIndex: 'reason',
                key: 'reason',
              },
            ]}
            rowKey="id"
          />
        )}
      </Modal>
      
      <AddOnHandInventoryForm
        isVisible={drawerVisible}
        onClose={onCloseDrawer}
        onSuccessCallBack={onSuccessAddOnHandInventory}
        editingInventory={editingInventory}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
      </div>
    </>
  );
};

export default OnHandInventory;