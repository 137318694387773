import React, { useState } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import setup from '../assets/setup.png'; // Import the logo image
import budget from '../assets/budget.png'; // Import the logo image
import users from '../assets/users.png'; // Import the logo image
import buy from '../assets/buy.png'; // Import the logo image
import order from '../assets/order.png'; // Import the logo image
import dashbaord from '../assets/dashboard.png'; // Import the logo image
import delivery from '../assets/delivery.png'; // Import the logo image
import waste from '../assets/wastelog.png'; // Import the logo image
import map from '../assets/map.png'; // Import the logo image
import liquor from '../assets/liquor.png'; // Import the logo image
import classification from '../assets/classification.png'; // Import the logo image
import measure from '../assets/measure.png'; // Import the logo image
import review from '../assets/review.png'; // Import the logo image
import masterdata from '../assets/masterdata.png'; // Import the logo image
import inventorycheck from '../assets/inventorycheck.png'; // Import the logo image
import warning from '../assets/warning.png'; // Import the logo image
import pricealert from '../assets/sales.png'; // Import the logo image
import payments from '../assets/payments.png'; // Import the logo image
import finance from '../assets/finance.png'; // Import the logo image
import whisk from '../assets/whisk.png'; // Import the logo image
import kitchen from '../assets/kitchen.png'; // Import the logo image
import recipe from '../assets/recipe.png'; // Import the logo image


import logout1 from '../assets/logout.png'; // Import the logo image


import { logout } from '../utils/functions';
import { saveAs } from 'file-saver';
import logo from '../assets/logo.png'; // Import the logo image

const MysideBar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  

  // Assuming you have the user's role stored in localStorage
  const userRole = localStorage.getItem('token1');
  const fullname = localStorage.getItem('token3');



  return (
    <div style={{ display: "flex", height: "100vh"}}
  >

 
      <Sidebar className="MysideBar" collapsed={collapsed}  >
      <div className="logo-containersb">
          <img src={logo} alt="logo" className="logosb" />
        </div>
        
        <Menu >

          <MenuItem className="menu1" icon={<MenuRoundedIcon />}>
          <h3><b>{fullname}</b></h3>
          </MenuItem>
          <hr  /> 
            <MenuItem className={location.pathname === "/" ? "activeMenuItem" : ""} icon={<img src={dashbaord} style={{ color: location.pathname === "/" ? "#505050" : "grey" }} />} component={<Link to="/" />}> Dashboard</MenuItem>
            <SubMenu label="Master Data" icon={<img src={masterdata} style={{ color: "grey" }} />}>
              <MenuItem className={location.pathname === "/category" ? "activeMenuItem" : ""} icon={<img src={liquor} style={{ color: location.pathname ==="/category" ? "#505050" : "grey" }} />} component={<Link to="/category" />}>Category</MenuItem>
              <MenuItem className={location.pathname === "/uom" ? "activeMenuItem" : ""} icon={<img src={measure} style={{ color: location.pathname ==="/uom" ? "#505050" : "grey" }} />} component={<Link to="/uom" />}>Unit of Measure</MenuItem>
              <MenuItem className={location.pathname === "/location" ? "activeMenuItem" : ""} icon={<img src={map} style={{ color: location.pathname ==="/location" ? "#505050" : "grey" }} />} component={<Link to="/location" />}>Locations</MenuItem>
              <MenuItem className={location.pathname === "/product" ? "activeMenuItem" : ""} icon={<img src={classification} style={{ color: location.pathname ==="/product" ? "#505050" : "grey" }} />} component={<Link to="/product" />}>Products</MenuItem>
              <MenuItem className={location.pathname === "/supplier" ? "activeMenuItem" : ""} icon={<img src={delivery} style={{ color: location.pathname ==="/supplier" ? "grey" : "#a9a9a9" }} />} component={<Link to="/supplier" />}>Suppliers</MenuItem>
            </SubMenu>
            <SubMenu label={("Buy")} icon={<img src={buy} style={{ color: "#a9a9a9" }} />}>
              <MenuItem className={location.pathname === "/purchase" ? "activeMenuItem" : ""} icon={<img src={order} style={{ color: location.pathname ==="/purchase" ? "grey" : "#a9a9a9" }} />} component={<Link to="/purchase" />}>Create Order</MenuItem>
              <MenuItem className={location.pathname === "/purchasereview" ? "activeMenuItem" : ""} icon={<img src={review} style={{ color: location.pathname ==="/purchasereview" ? "grey" : "#a9a9a9" }} />} component={<Link to="/purchasereview" />}>Order Review</MenuItem>
              </SubMenu>

              <SubMenu label={("Alerts")} icon={<img src={warning} style={{ color: "#a9a9a9" }} />}>
              <MenuItem className={location.pathname === "/pricealert" ? "activeMenuItem" : ""} icon={<img src={pricealert} style={{ color: location.pathname ==="/pricealert" ? "grey" : "#a9a9a9" }} />} component={<Link to="/pricealert" />}>Price Alerts</MenuItem>
              <MenuItem className={location.pathname === "/inventorycheck" ? "activeMenuItem" : ""} icon={<img src={inventorycheck} style={{ color: location.pathname ==="/inventorycheck" ? "grey" : "#a9a9a9" }} />} component={<Link to="/inventorycheck" />}>Cycle Count</MenuItem>
              </SubMenu>
              <SubMenu label={("Finances")} icon={<img src={finance} style={{ color: "#a9a9a9" }} />}>
              <MenuItem
            className={location.pathname === "/budgets" ? "activeMenuItem" : ""}
            icon={<img src={budget} alt="Budgets" style={{ width: '20px', height: '20px', color: location.pathname === "/budgets" ? "#505050" : "grey" }} />}
            component={<Link to="/budgets" />}
          >
            Budgets
          </MenuItem>

              <MenuItem
            className={location.pathname === "/billpayment" ? "activeMenuItem" : ""}
            icon={<img src={payments} alt="Setup" style={{ width: '20px', height: '20px', color: location.pathname === "/billpayment" ? "#505050" : "grey" }} />}
            component={<Link to="/billpayment" />}
          >
            Bills
          </MenuItem>

          <MenuItem
            className={location.pathname === "/wastelog" ? "activeMenuItem" : ""}
            icon={<img src={waste} alt="Budgets" style={{ width: '20px', height: '20px', color: location.pathname === "/wastelog" ? "#505050" : "grey" }} />}
            component={<Link to="/wastelog" />}
          >
            Waste Log
          </MenuItem>


          </SubMenu>

              <SubMenu label={("Recipe")} icon={<img src={recipe} style={{ color: "#a9a9a9" }} />}>
              <MenuItem className={location.pathname === "/equipment" ? "activeMenuItem" : ""} icon={<img src={kitchen} style={{ color: location.pathname ==="/equipment" ? "grey" : "#a9a9a9" }} />} component={<Link to="/equipment" />}>Equipment</MenuItem>
              <MenuItem className={location.pathname === "/recipe" ? "activeMenuItem" : ""} icon={<img src={whisk} style={{ color: location.pathname ==="/recipe" ? "grey" : "#a9a9a9" }} />} component={<Link to="/recipe" />}>Manage</MenuItem>
           
              </SubMenu>

              <MenuItem
            className={location.pathname === "/onhand" ? "activeMenuItem" : ""}
            icon={<img src={setup} alt="Setup" style={{ width: '20px', height: '20px', color: location.pathname === "/onhand" ? "#505050" : "grey" }} />}
            component={<Link to="/onhand" />}
          >
            Inventory
          </MenuItem>


            <MenuItem className={location.pathname === "/users" ? "activeMenuItem" : ""} icon={<img src={users} alt="Users" style={{ width: '20px', height: '20px', color: location.pathname === "/users" ? "#505050" : "grey" }} />} component={<Link to="/users" />}> Users</MenuItem>
            <MenuItem onClick={logout} icon={<img src={logout1} style={{ color: location.pathname === "/logout" ? "#505050" : "#757575" }} />}> Logout</MenuItem>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default MysideBar;