import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button } from "antd";
import { AuthTokenType, DataProps, AddSupplierFormProps } from "../utils/types";
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { suppliersUrl } from "../utils/network";

const AddSupplierForm: FC<AddSupplierFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingSupplier,
  onCloseWithoutEditing,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (editingSupplier) {
      form.setFieldsValue(editingSupplier);
      setPhone(editingSupplier.phone || ""); // Set phone if editing
    } else {
      form.resetFields();
      setPhone(""); // Reset phone field
    }
  }, [editingSupplier, form]);

  const formatPhoneNumber = (value: string) => {
    let numbers = value.replace(/\D/g, "").slice(0, 10);
    if (numbers.length <= 3) {
      return `(${numbers}`;
    } else if (numbers.length <= 6) {
      return `(${numbers.slice(0, 3)})-${numbers.slice(3)}`;
    } else {
      return `(${numbers.slice(0, 3)})-${numbers.slice(3, 6)}-${numbers.slice(6)}`;
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(formatPhoneNumber(e.target.value));
    form.setFieldsValue({ phone: formatPhoneNumber(e.target.value) });
  };

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let response: AxiosResponse;
      if (editingSupplier) {
        response = await axios.put(`${suppliersUrl}/${editingSupplier.id}/`, values, headers);
      } else {
        response = await axios.post(`${suppliersUrl}/`, values, headers);
      }

      setLoading(false);
      if (response) {
        form.resetFields();
        setPhone(""); // Reset phone field after submission
        onSuccessCallBack?.();
        onClose?.();
      }
    } catch (error) {
      notification.error({ message: "Operation Error" });
      setLoading(false);
    }
  };

  const handleFormClose = () => {
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify if closed without editing
    }
    form.resetFields();
    setPhone(""); // Reset phone field
    onClose?.();
  };

  return (
    <Drawer
      title={editingSupplier ? "Edit Supplier" : "Add Supplier"}
      open={isVisible}
      onClose={handleFormClose}
      destroyOnClose
      width={400}
    >
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please input the supplier name!" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Email" name="email" rules={[{ required: true, message: "Please input the supplier email!" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Address" name="address" rules={[{ required: true, message: "Please input the supplier address!" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="City" name="city" rules={[{ required: true, message: "Please input the supplier city!" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="State" name="state" rules={[{ required: true, message: "Please input the supplier state!" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Country" name="country" rules={[{ required: true, message: "Please input the supplier country!" }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone"
          rules={[
            { required: true, message: "Please input the supplier phone number!" },
            { pattern: /^\(\d{3}\)-\d{3}-\d{4}$/, message: "Phone must be in (555)-555-5555 format!" },
          ]}
        >
          <Input value={phone} onChange={handlePhoneChange} placeholder="(555)-555-5555" maxLength={14} />
        </Form.Item>
        <Form.Item label="Type" name="type" rules={[{ required: true, message: "Please input the supplier type!" }]}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button className="bbutton" htmlType="submit" type="primary" block loading={loading}>
            {editingSupplier ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddSupplierForm;
