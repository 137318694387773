import React, { FC, useState, useEffect } from 'react';
import { Button, Table, Modal, notification, Select, Input, Tooltip } from 'antd';
import { useGetOnhandIventory, useGetOnhandIventoryupdated } from '../utils/hooks';
import { AuthTokenType, category, OnhandInventoryProps } from '../utils/types';
import { getAuthToken, getOnhandInventory, getOnhandInventoryupdated, getproductinventory } from '../utils/functions';
import { useGetCategory, useGetLocation } from '../utils/hooks';
import axios from 'axios';
import { onhandUrl } from '../utils/network';
import SaveIcon from '@ant-design/icons/SaveOutlined';
import map from '../assets/map.png'; // Import the logo image
import liquor from '../assets/liquor.png'; // Import the logo image
import classification from '../assets/classification.png'; // Import the logo image
import market from '../assets/market-segment.png'; // Import the logo image
import overflow from '../assets/overflow.png'; // Import the logo image
import reason from '../assets/reason.png'; // Import the logo image
import exchange from '../assets/exchange.png'; // Import the logo image
import edit1 from '../assets/edit.png'; // Import the logo image
import close1 from '../assets/close.png'; // Import the logo image
import measure from '../assets/measure.png'; // Import the logo image


const { Option } = Select;

const InventoryCount: FC = () => {
  const [onhandinventory, setOnHandInventory] = useState<OnhandInventoryProps[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [categories, setCategories] = useState<category[]>([]);
  const [location, setLocation] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState<number | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<number | null>(null);
  const [showRecordInventoryColumns, setShowRecordInventoryColumns] = useState<boolean>(false);

  const handleRecordInventory = () => {
    setShowRecordInventoryColumns(true);
  };

  const handleCancelRecordInventory = () => {
    setShowRecordInventoryColumns(false);
  };

  const handleSaveInventory = async () => {
    const headers = getAuthToken() as AuthTokenType;

    // Prepare the data to send
    const updates = onhandinventory.map(item => ({
      id: item.id,
      changedunits: item.changedunits,
      reason: item.reason
    }));

    try {
      // Send each update sequentially
      for (const values of updates) {
        await axios.patch(`${onhandUrl}/${values.id}`, values, headers);
      }

      notification.success({
        message: 'Inventory Recorded Successfully',
      });
      setShowRecordInventoryColumns(false);
      getOnhandInventoryupdated(setOnHandInventory, setFetching);
    } catch (error) {
      notification.error({
        message: 'Error Recording Inventory',
      });
    }
  };

  // Filtering the data based on search, category, subcategory, and location
  const filteredData = onhandinventory.filter((record) => {
    return (
      // Search filter
      Object.values(record).some((value) =>
        value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      ) &&
      // Category filter
      (selectedCategory ? record.sku_code.category.parent.id === selectedCategory : true) &&
      // Subcategory filter
      (selectedSubCategory ? record.sku_code.category.id === selectedSubCategory : true) &&
      // Location filter
      (selectedLocation ? record.locationid === selectedLocation : true)
    );
  });

  // Check if any filter is selected
  const isAnyFilterSelected = selectedCategory || selectedSubCategory || selectedLocation;

  useGetCategory(setCategories, setFetching);
  useGetLocation(setLocation, setFetching);
  useGetOnhandIventoryupdated(setOnHandInventory, setFetching);

  const columns = [
    {
      title: () => (
        <span>
          <img src={liquor} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Item
        </span>
      ),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: () => (
        <span>
          <img src={map} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Location
        </span>
      ),
      dataIndex: 'storage',
      key: 'storage',
    },
    {
      title: () => (
        <span>
          <img src={overflow} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          on Hand
        </span>
      ),
      dataIndex: 'totalunits',
      key: 'totalunits',
    },
    {
      title: () => (
        <span>
          <img src={measure} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          UOM
        </span>
      ),
      dataIndex: ['sku_code', 'sku_uom', 'name'],
      key: ['sku_code', 'sku_uom', 'name'],
    },
    ...(showRecordInventoryColumns
      ? [
          {
            title: () => (
              <span>
                <img src={exchange} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
                Changed Units
              </span>
            ),
            dataIndex: 'changedunits',
            key: 'changedunits',
            render: (text: number, record: OnhandInventoryProps) => (
              <Input
              className='customInput'
                type="number"
                value={text}
                onChange={(e) => {
                  const newValue = parseFloat(e.target.value);
                  setOnHandInventory((prev) =>
                    prev.map((item) =>
                      item.id === record.id ? { ...item, changedunits: newValue } : item
                    )
                  );
                }}
              />
            ),
          },

          
          {
            title: () => (
              <span>
                <img src={reason} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
                Reason
              </span>
            ),
       dataIndex: 'reason',
            key: 'reason',
            render: (text: string, record: OnhandInventoryProps) => (
              <Select
              className="customSelect"
                value={text || 'Inventory Recount'}
                onChange={(value) => {
                  setOnHandInventory((prev) =>
                    prev.map((item) =>
                      item.id === record.id ? { ...item, reason: value } : item
                    )
                  );
                }}
              >
                <Option value="Stock Received">Stock Received</Option>
                <Option value="Inventory Recount">Inventory Recount</Option>
                <Option value="Damage">Damage</Option>
                <Option value="Theft">Theft</Option>
                <Option value="Loss">Loss</Option>
                <Option value="Restock Return">Restock Return</Option>
              </Select>
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      <div className="card">
      <div className="borderline">

        <div className="cardHeader">
          
          <h2 className="headContent">Inventory</h2>
          </div>
         <div className='divContainer'>
          <div className='leftContent'>
          <Select
           className="customSelect"
              style={{ width: 200, marginRight: 10 }}
              placeholder="Select Category"
              onChange={setSelectedCategory}
              value={selectedCategory}
            >
              <Option value={null}><span><img src={classification} alt="category icon" style={{ width: '16px', height: '16px', marginRight: '8px' }} /> Select Category</span></Option>
              {categories.filter((cat) => cat.is_parent).map((cat) => (
                <Option key={cat.id} value={cat.id}>
                  {cat.name}
                </Option>
              ))}
            </Select>

            <Select
             className="customSelect"
              style={{ width: 200, marginRight: 10 }}
              placeholder="Select Sub Category"
              onChange={setSelectedSubCategory}
              value={selectedSubCategory}
            >
              <Option value={null}><span><img src={market} alt="category icon" style={{ width: '16px', height: '16px', marginRight: '8px' }} /> Select Sub Category</span></Option>
              {categories.filter((cat) => !cat.is_parent).map((cat) => (
                <Option key={cat.id} value={cat.id}>

                  {cat.name}
                </Option>
              ))}
            </Select>

            <Select
             className="customSelect"
              style={{ width: 200, marginRight: 10 }}
              placeholder="Select Location"
              onChange={setSelectedLocation}
              value={selectedLocation}
            >
              <Option value={null}><span><img src={map} alt="category icon" style={{ width: '16px', height: '16px', marginRight: '8px' }} /> Select Location</span></Option>
              {location.map((loc) => (
                <Option key={loc.id} value={loc.id}>

                  {loc.name}
                </Option>
              ))}
            </Select>


          </div>
          <div className="rightContent">

            <Tooltip title={showRecordInventoryColumns ? 'Cancel Recording' : 'Record Inventory'}>
              <Button
                className="bbutton"
                onClick={showRecordInventoryColumns ? handleCancelRecordInventory : handleRecordInventory}
                style={{ marginRight: '5px' }}
                icon={
                  showRecordInventoryColumns ? (
                    <img src={close1} alt="undo" style={{ width: '20px', height: '20px' }} />
                  ) : (
                    <img src={edit1} alt="check-circle" style={{ width: '20px', height: '20px' }} />
                  )}
              />
            </Tooltip>

            {showRecordInventoryColumns && (
              <Tooltip title="Save Inventory">
                <Button
                  className="bbutton"
                  onClick={handleSaveInventory}
                  style={{ marginRight: '5px' }}
                  icon={<SaveIcon />}
                />
              </Tooltip>
            )}
          
        </div>
        </div>
        {/* Display the table only if any filter is selected */}
        {isAnyFilterSelected ? (
          <Table
            className="custom-table"
            dataSource={filteredData}
            columns={columns as any}
            rowKey="id"
            loading={fetching}
          />
        ) : (
          <div><br/>
            No data to display. Please select a filter.</div>
        )}
      </div>
      </div>
    </>
  );
};

export default InventoryCount;