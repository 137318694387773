import React, { FC, useState } from "react";
import { Button, Table, Modal, notification, Input } from "antd";
import { AuthTokenType } from "../utils/types";
import { getAuthToken, getequipment} from "../utils/functions";
import axios from "axios";
import ContentLayoutnew from "../components/ContentLayoutnew";
import { useGetequipment } from "../utils/hooks";
import { equipmentURL } from "../utils/network";

import save from '../assets/save.png'; // Import the logo image

import close1 from '../assets/close.png'; // Import the logo image

import delete1 from '../assets/delete.png'; // Import the logo image
import edit1 from '../assets/edit.png'; // Import the logo image
import name from '../assets/name.png'; // Import the logo image
import action from '../assets/action.png'; // Import the logo image
import description from '../assets/description.png'; // Import the logo image


const Equipment: FC = () => {
  const [equipments, setEquipments] = useState<any[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [newEquipment, setNewEquipment] = useState<any>({
    id: -1,
    name: "",
    description: "",
  });
  const [editingEquipment, setEditingEquipment] = useState<any | null>(null);

  useGetequipment(setEquipments, setFetching);

  const handleDelete = (recordId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this equipment?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          await axios.delete(`${equipmentURL}${recordId}`, headers);
          getequipment(setEquipments, setFetching);
          notification.success({ message: "Equipment deleted successfully!" });
        } catch (error) {
          notification.error({ message: "Failed to delete Equipment!" });
        }
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const handleDeleteSelected = async (ids: number[]) => {
    try {
      const headers = getAuthToken() as AuthTokenType;
      await Promise.all(ids.map((id) => axios.delete(`${equipmentURL}${id}`, headers)));
      getequipment(setEquipments, setFetching);
      notification.success({ message: "Selected Equipments deleted successfully!" });
    } catch (error) {
      notification.error({ message: "Failed to delete selected Equipments!" });
    }
  };

  const handleAddRow = () => {
    setIsAdding(true);
    setNewEquipment({
      id: -1,
      name: "",
      description: "",
    });
  };

  const handleSaveNewEquipment = async () => {
    try {
      const headers = getAuthToken() as AuthTokenType;
      const response = await axios.post(equipmentURL, newEquipment, headers);
      setEquipments((prev) => [...prev, response.data]);
      setNewEquipment({ id: -1, name: "", description: "" });
      setIsAdding(false);
      notification.success({ message: "Equipment added successfully!" });
    } catch (error) {
      notification.error({ message: "Failed to add Equipment!" });
    }
  };

  const handleEdit = (record: any) => {
    setEditingEquipment(record);
  };

  const handleSaveEdit = async (equipment: any) => {
    try {
      const headers = getAuthToken() as AuthTokenType;
      await axios.put(`${equipmentURL}${equipment.id}/`, equipment, headers);
      getequipment(setEquipments, setFetching);
      setEditingEquipment(null);
      notification.success({ message: "Equipment updated successfully!" });
    } catch (error) {
      notification.error({ message: "Failed to update Equipment!" });
    }
  };

  const columns = [
    {
      title: () => (
        <span>
          <img src={name} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Name
        </span>
      ),
      dataIndex: "name",
      key: "name",
      render: (text: string, record: any) => {
        if (isAdding && record.id === -1) {
          return (
            <Input
              value={newEquipment.name}
              onChange={(e) => setNewEquipment({ ...newEquipment, name: e.target.value })}
              style={{ width: "100%" }}
            />
          );
        }
        if (editingEquipment && editingEquipment.id === record.id) {
          return (
            <Input
              value={editingEquipment.name}
              onChange={(e) => setEditingEquipment({ ...editingEquipment, name: e.target.value })}
            />
          );
        }
        return text;
      },
    },
    {
      title: () => (
        <span>
          <img src={description} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Description
        </span>
      ),
      dataIndex: "description",
      key: "description",
      render: (text: string, record: any) => {
        if (isAdding && record.id === -1) {
          return (
            <Input
              value={newEquipment.description}
              onChange={(e) =>
                setNewEquipment({ ...newEquipment, description: e.target.value })
              }
              style={{ width: "100%" }}
            />
          );
        }
        if (editingEquipment && editingEquipment.id === record.id) {
          return (
            <Input
              value={editingEquipment.description}
              onChange={(e) =>
                setEditingEquipment({ ...editingEquipment, description: e.target.value })
              }
            />
          );
        }
        return text;
      },
    },
    {
      title: () => (
        <span>
          <img src={action} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Action
        </span>
      ),
      key: "actions",
      render: (text: string, record: any) => {
        if (isAdding && record.id === -1) {
          return (
            <span>
              <a onClick={handleSaveNewEquipment}>
                <img src={save} alt="Save" style={{ width: "20px", height: "20px", marginRight: "8px" }} />
              </a>
              <span> | </span>
              <a
                onClick={() => {
                  setIsAdding(false);
                  setNewEquipment({ id: -1, name: "", description: "" });
                }}
              >
                <img src={close1} alt="Cancel" style={{ width: "20px", height: "20px", marginLeft: "8px" }} />
              </a>
            </span>
          );
        }
  
        if (editingEquipment && editingEquipment.id === record.id) {
          return (
            <span>
              <a onClick={() => handleSaveEdit(editingEquipment)}>
                <img src={edit1} alt="Save" style={{ width: "20px", height: "20px", marginRight: "8px" }} />
              </a>
              <span> | </span>
              <a onClick={() => setEditingEquipment(null)}>
                <img src={edit1} alt="Cancel" style={{ width: "20px", height: "20px", marginLeft: "8px" }} />
              </a>
            </span>
          );
        }
  
        return (
          <span>
            <a onClick={() => handleEdit(record)}>
              <img src={edit1} alt="Edit" style={{ width: "20px", height: "20px", marginRight: "8px" }} />
            </a>
            <span> | </span>
            <a onClick={() => handleDelete(record.id)}>
              <img src={delete1} alt="Delete" style={{ width: "20px", height: "20px", marginLeft: "8px" }} />
            </a>
          </span>
        );
      },
    },
  ];

  const dataSource = isAdding ? [...equipments, { id: -1, name: newEquipment.name }] : equipments;

  return (
    <ContentLayoutnew
      pageTitle="Equipment"
      buttontitle="Add Equipment"
      setModalState={handleAddRow}
      dataSource={dataSource as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
      onAddRow={handleAddRow} onDeleteSelected={handleDeleteSelected}
      />
    );
  };
  
  export default Equipment;
