//export const BaseUrl="http://127.0.0.1:8000/api/"

//export const BaseUrl="http://52.200.175.226/api/"
export const BaseUrl="https://unifiedinventory.com/api/"

//export const BaseUrl="http://3.222.80.121/api/"


export const LoginUrl = BaseUrl+ "user/login"
export const MeUrl = BaseUrl + "user/Me"
export const CreateUserUrl = BaseUrl + "user/create-user"
export const UsersUrl = BaseUrl + "user/users"
export const UpdatePasswordUrl = BaseUrl + "user/update-password"
export const ForgotPasswordUrl = BaseUrl + "user/ForgotPasswordView"
export const ResetPasswordUrl = BaseUrl + "user/ResetPasswordView"



export const categoriesUrl = BaseUrl + "masterdata/categories"
export const unitofmeasuresUrl = BaseUrl + "masterdata/unitofmeasures"
export const currenciesUrl = BaseUrl + "masterdata/currencies"
export const locationsUrl = BaseUrl + "masterdata/locations"
export const productsUrl = BaseUrl + "masterdata/products"
export const productswithinventoryUrl = BaseUrl + "masterdata/productswithinventory"



export const onhandUrl = BaseUrl + "transactiondata/onhandinventory"
export const InventoryUrl = BaseUrl + "transactiondata/InventoryViewSet"
export const BudgetUrl = BaseUrl + "transactiondata/budgets"
export const WastelogUrl = BaseUrl + "transactiondata/wastelog"
export const WastesummaryUrl = BaseUrl + "transactiondata/wastelog/top_waste_data"
export const pricealertUrl = BaseUrl + "transactiondata/pricealert"
export const inventorycheckUrl = BaseUrl + "transactiondata/inventorycheck"
export const billpaymentUrl = BaseUrl + "transactiondata/billpayment"

export const SummaryMasterUrl = BaseUrl + "transactiondata/summarymasterview"
export const SummaryTransactionUrl = BaseUrl + "transactiondata/summarytransactionview"



export const InventorySummaryUrl = BaseUrl + "transactiondata/onhandsummaryview"

export const masterdatauploadURL = BaseUrl + "masterdata/UploadCSV/"

export const InventoryMD04Url = BaseUrl + "transactiondata/InventoryViewSetMD04"
export const SankeyChartURL = BaseUrl + "transactiondata/SankeyChartView"
export const productpriceViewSetURL = BaseUrl + "masterdata/productpriceViewSet"
export const productinventoryViewSetURL = BaseUrl + "masterdata/productinventoryViewSet"

export const suppliersUrl = BaseUrl + "masterdata/suppliers"
export const purchaseorderUrl = BaseUrl + "transactiondata/purchaseorder"
export const sendpoUrl = BaseUrl + "transactiondata/purchaseorder/send_email_purchase_order"
export const viewpoUrl = BaseUrl + "transactiondata/purchaseorder/view_purchase_order"



export const equipmentURL = BaseUrl + "masterdata/equipment/"
export const recipeURL = BaseUrl + "masterdata/recipe/"

export const budgetcomparisonURL = BaseUrl + "transactiondata/budgetcomparison"
export const purchasereportURL = BaseUrl + "transactiondata/budgetcomparison/payment_by_bill_type"
export const budgetoverviewURL = BaseUrl + "transactiondata/budgetcomparison/budget_overview"
