import React, { FC, useState, useEffect, useRef } from "react";
import { Drawer, Form, Input, Button, Row, Col, notification, Select, Table } from "antd";
import { AddOnRecipeForm, AuthTokenType, Equipment, product } from "../utils/types";
import { getAuthToken } from "../utils/functions";
import axios from "axios";
import { recipeURL } from "../utils/network";
import { useGetequipment, useGetProduct } from "../utils/hooks";
import up from '../assets/up.png'; // Import the logo image
import down from '../assets/down.png'; // Import the logo image

const ImageHolder: string = require("../assets/ImageHolder.svg").default;

const AddRecipeForm: FC<AddOnRecipeForm> = ({
  isVisible,
  onClose,
  onSuccessCallBack,
  editingRecipe,
  onCloseWithoutEditing, // new prop

}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<product[]>([]);
  const [equipmentDropdown, setEquipmentDropdown] = useState<Equipment[]>([]); // New state for dropdown options
  const [selectedEquipment, setSelectedEquipment] = useState<{ equipmentId: number }[]>([]); // State for selected equipment
  const [ingredients, setIngredients] = useState<{ ingredientId: number; quantity: number; cost: number;uom:string }[]>([]);
  const [totalCost, setTotalCost] = useState(0);

  const [fetchingProducts, setFetchingProducts] = useState<boolean>(true);
  const [fetchingEquipment, setFetchingEquipment] = useState<boolean>(true);

  const [preparationSteps, setPreparationSteps] = useState<{ number: number; step: string }[]>([]);
  const [cookingSteps, setCookingSteps] = useState<{ number: number; step: string }[]>([]);
  const [platingSteps, setPlatingSteps] = useState<{ number: number; step: string }[]>([]);
  const [image, setImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const fileSelect = useRef<HTMLInputElement>(null);


  const moveStep = (index: number, direction: "up" | "down", setter: Function, steps: any[]) => {
    const newSteps = [...steps];
    if (direction === "up" && index > 0) {
      // Swap with the previous step
      [newSteps[index - 1], newSteps[index]] = [newSteps[index], newSteps[index - 1]];
    } else if (direction === "down" && index < newSteps.length - 1) {
      // Swap with the next step
      [newSteps[index], newSteps[index + 1]] = [newSteps[index + 1], newSteps[index]];
    }
    // Renumber steps after swapping
    newSteps.forEach((step, i) => (step.number = i + 1));
    setter(newSteps);
  };

  // Fetch products and equipment data
  useGetProduct(setProducts, setFetchingProducts);
  useGetequipment(setEquipmentDropdown, setFetchingEquipment); // Load available equipment for dropdown

  const handleFormClose = () => {
    form.resetFields();
    setSelectedEquipment([]); // Clear equipment selection
    setIngredients([]); // Clear ingredients list
    setPreparationSteps([]); // Clear preparation steps
    setCookingSteps([]); // Clear cooking steps
    setPlatingSteps([]); // Clear plating steps
    setImage(null); // Clear image selection
    setImageUrl(null); // Clear image preview
    setTotalCost(0); // Reset total cost

    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const selectedImage = fileList[0];
      setImage(selectedImage); // Update state with the selected file

      // Create a URL object for the selected image and set it as the background
      const imageUrl = URL.createObjectURL(selectedImage);
      setImageUrl(imageUrl);
    }
  };

  useEffect(() => {
    if (editingRecipe) {
      form.setFieldsValue({
        ...editingRecipe,
      });
      const updatedIngredients = (editingRecipe.ingredients || []).map((ingredient) => ({
        ingredientId: ingredient.ingredient.id,
        quantity: ingredient.quantity,
        cost: ingredient.ingredient.costperwholeunit/ingredient.ingredient.subunitsperwholeunit,
        uom:ingredient.ingredient.sku_uom.name
      }));
      setIngredients(updatedIngredients);
      setSelectedEquipment(
        (editingRecipe.equipments || []).map((equip) => ({
          equipmentId: equip.equipment.id,
        }))
      );
      setPreparationSteps(editingRecipe.preparation || []);
      setCookingSteps(editingRecipe.cooking || []);
      setPlatingSteps(editingRecipe.plating || []);
    } else {
      form.resetFields();
      setSelectedEquipment([]); // Reset equipment on new form
      setIngredients([]);
      setPreparationSteps([]);
      setCookingSteps([]);
      setPlatingSteps([]);
    }
  }, [editingRecipe, form]);


  const handleAddStep = (setter: Function, steps: any[]) => {
    setter([...steps, { number: steps.length + 1, step: "" }]);
  };

  const handleRemoveStep = (index: number, setter: Function, steps: any[]) => {
    const newSteps = [...steps];
    newSteps.splice(index, 1);
    setter(newSteps);
  };

  const handleStepChange = (index: number, value: string, setter: Function, steps: any[]) => {
    const newSteps = [...steps];
    newSteps[index].step = value;
    setter(newSteps);
  };

  const stepColumns = (setter: Function, steps: any[]) => [
    {
      title: "Step Number",
      dataIndex: "number",
      render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
    },
    {
      title: "Step Description",
      dataIndex: "step",
      render: (text: string, _: any, index: number) => (
        <Input value={text} onChange={(e) => handleStepChange(index, e.target.value, setter, steps)} />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, __: any, index: number) => (
        <div>
          <Button
            type="link"
            onClick={() => moveStep(index, "up", setter, steps)}
            disabled={index === 0} // Disable if it's the first step
          >
            Move Up
          </Button>
          <Button
            type="link"
            onClick={() => moveStep(index, "down", setter, steps)}
            disabled={index === steps.length - 1} // Disable if it's the last step
          >
            Move Down
          </Button>
          <Button type="link" onClick={() => handleRemoveStep(index, setter, steps)}>
            Remove
          </Button>
        </div>
      ),
    }
  ];


  // Handle adding new equipment
  const handleAddEquipment = () => {
    setSelectedEquipment([...selectedEquipment, { equipmentId: 0 }]);
  };

  // Handle removing equipment
  const handleRemoveEquipment = (index: number) => {
    const newEquipment = [...selectedEquipment];
    newEquipment.splice(index, 1);
    setSelectedEquipment(newEquipment);
  };

  // Handle equipment selection
  const handleEquipmentChange = (index: number, value: any) => {
    const newEquipment = [...selectedEquipment];
    newEquipment[index].equipmentId = value;
    setSelectedEquipment(newEquipment);
  };

  // Handle adding new ingredient
  const handleAddIngredient = () => {
    setIngredients([...ingredients, { ingredientId: 0, quantity: 0, cost: 0,uom:"" }]);
  };

  // Handle removing ingredient
  const handleRemoveIngredient = (index: number) => {
    const newIngredients = [...ingredients];
    newIngredients.splice(index, 1);
    setIngredients(newIngredients);
    calculateTotalCost(newIngredients);
  };

  // Handle ingredient selection
  const handleIngredientChange = (index: number, value: any) => {
    const newIngredients = [...ingredients];
    newIngredients[index].ingredientId = value;
    const selectedProduct = products.find((product) => product.id === value);
    if (selectedProduct) {
      newIngredients[index].cost = selectedProduct.costperwholeunit/selectedProduct.subunitsperwholeunit;
      newIngredients[index].uom = selectedProduct.sku_uom?.name || "N/A";
    }
    setIngredients(newIngredients);
    calculateTotalCost(newIngredients);
  };

  // Calculate total cost of recipe based on ingredients
  const calculateTotalCost = (updatedIngredients: any[]) => {
    const newCost = updatedIngredients.reduce(
      (acc, ingredient) => acc + ingredient.cost * parseFloat(ingredient.quantity || '0'),
      0
    );
  
    setTotalCost(parseFloat(newCost.toFixed(2))); // Rounds to 2 decimal places
  };

  const onSubmit = async (values: any) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;
  
    try {
      let formData = new FormData(); // Create FormData object
  
      // Append all other fields except image
      Object.entries(values).forEach(([key, value]) => {
        if (value !== undefined && value !== null && key !== 'image') {
          formData.append(key, value.toString());
        }
      });
  
      // Append image if exists
      if (image) {
        formData.append('image', image);
      }

  
      // Append JSON objects as strings
      formData.append('equipment', JSON.stringify(selectedEquipment.map(equip => ({ equipment_id: equip.equipmentId }))));
      formData.append('ingredients', JSON.stringify(ingredients.map(ingredient => ({
        ingredient_id: ingredient.ingredientId,
        quantity: ingredient.quantity,
      }))));
      formData.append('preparation', JSON.stringify(preparationSteps));
      formData.append('cooking', JSON.stringify(cookingSteps));
      formData.append('plating', JSON.stringify(platingSteps));
      formData.append('cost', totalCost.toString());
  
      let response;
      if (editingRecipe) {
        response = await axios.put(`${recipeURL}${editingRecipe.id}/`, formData, headers);
      } else {
        response = await axios.post(recipeURL, formData, headers);
      }
  
      if (response) {
        notification.success({ message: "Recipe saved successfully!" });
        setSelectedEquipment([]); // Clear equipment selection
        setIngredients([]); // Clear ingredients list
        setPreparationSteps([]); // Clear preparation steps
        setCookingSteps([]); // Clear cooking steps
        setPlatingSteps([]); // Clear plating steps
        setImage(null); // Clear image selection
        setImageUrl(null); // Clear image preview
        setTotalCost(0); // Reset total cost
        form.resetFields();
        onSuccessCallBack(); // Callback to refetch data
        onClose(); // Close the form
      }
    } catch (error) {
      notification.error({ message: "Failed to save recipe!" });
    } finally {
      setLoading(false);
    }
  };

  // Ingredient table columns with updated cost render logic
  const ingredientColumns = [
    {
      title: "Ingredient",
      dataIndex: "ingredientId",
      render: (text: any) => (
        <Select
          value={text}
          style={{ width: "100%" }}
          onChange={(value) => handleIngredientChange(ingredients.findIndex(ingredient => ingredient.ingredientId === text), value)}
        >
          {products.map((product) => (
            <Select.Option key={product.id} value={product.id}>
              {product.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      render: (text: any, record: any, index: number) => (
        
        <Input
          type="number"
          step="0.01" // Allows decimal input
          value={text ?? ""} // Ensures controlled input
          onChange={(e) => {
            const newIngredients = [...ingredients];
            const inputValue = e.target.value;
    
            // Convert to number, default to 0 if empty
            newIngredients[index].quantity = inputValue === "" ? 0 : parseFloat(inputValue);
    
            setIngredients(newIngredients);
            calculateTotalCost(newIngredients);
          }}
        />
      ),
    },
    {
      title: "UOM",
      dataIndex: "uom",
      render: (text: any, record: any) => {
        return <span>{record.uom || "N/A"}</span>;
      },
    },
    
    {
      title: "Cost",
      dataIndex: "cost",
      render: (text: any) => (
        <span>{!isNaN(text) && text !== null ? `$${parseFloat(text).toFixed(2)}` : "$0.00"}</span>
      ),
    },
    {
      title: "Total Cost",
      dataIndex: "totalCost",
      render: (text: any, record: any) => {
        const totalCost = record.quantity * record.cost;
        return <span>{!isNaN(totalCost) ? `$${totalCost.toFixed(2)}` : "$0.00"}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any, index: number) => (
        <Button type="link" onClick={() => handleRemoveIngredient(index)}>
          Remove
        </Button>
      ),
    },
  ];

  // Equipment table columns with equipment selection
  const equipmentColumns = [
    {
      title: "Equipment",
      dataIndex: "equipmentId",
      render: (text: any, record: any, index: number) => (
        <Select
          value={text}
          style={{ width: "100%" }}
          onChange={(value) => handleEquipmentChange(index, value)}
        >
          {equipmentDropdown.map((equip) => (
            <Select.Option key={equip.id} value={equip.id}>
              {equip.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any, index: number) => (
        <Button type="link" onClick={() => handleRemoveEquipment(index)}>
          Remove
        </Button>
      ),
    },
  ];

  return (
    <Drawer
      title={<h2 style={{ color: "#008080", margin: 0 }}>{editingRecipe ? "Edit Recipe" : "Add Recipe"}</h2>}
      visible={isVisible}
      onClose={handleFormClose}
      destroyOnClose
      width={800} // Increased drawer width
      bodyStyle={{ padding: "20px", backgroundColor: "#f5f7fa" }}
    >
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        
        {/* Section 1: Recipe Details */}
        <div className="section">
          <div className="sectionHeader">
            <h3>Recipe Details</h3>
          </div>
                          <Form.Item
                    label={("Recipe Image")}
                    name="image"
                  >
                    <div
                      className="imageview"
                      onClick={() => !loading && fileSelect.current?.click()}
                      style={{
                        backgroundImage: `url(${imageUrl ? imageUrl : ImageHolder})`
                      }}
                    />
                    <input type="file" style={{ display: "none" }} ref={fileSelect} onChange={handleFileChange} />
                  </Form.Item>
          
          <div className="sectionBody">
          <Form.Item label="Recipe Id" name="recipeid" rules={[{ required: true, message: "Please input recipe id!" }]}>
              <Input placeholder="Enter Recipe Id" />
            </Form.Item>
      
            <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please input recipe name!" }]}>
              <Input placeholder="Enter Recipe Name" />
            </Form.Item>
            <Form.Item label="Description" name="description" rules={[{ required: true, message: "Please input Description!" }]}>
              <Input.TextArea placeholder="Enter Recipe Description" rows={4} />
            </Form.Item>
  

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Prep Time " name="preparation_time" rules={[{ required: true, message: "Enter time!" }]}>
                  <Input placeholder="in minutes" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Cooking Time" name="cooking_time" rules={[{ required: true, message: "Enter time!" }]}>
                  <Input placeholder="in minutes" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
  
        {/* Section 2: Equipment Details */}
        <div className="section">
          <div className="sectionHeader">
            <h3>Equipment Details</h3>
            <Button type="primary" onClick={handleAddEquipment} style={{ backgroundColor: "#008080", borderColor: "#008080" }}>+ Add Equipment</Button>
          </div>
          <div className="sectionBody">
            <Table columns={equipmentColumns} dataSource={selectedEquipment} rowKey="equipmentId" pagination={false} size="small" />
          </div>
        </div>
  
        {/* Section 3: Ingredient Details */}
        <div className="section">
          <div className="sectionHeader">
            <h3>Ingredient Details</h3>
            <Button type="primary" onClick={handleAddIngredient} style={{ backgroundColor: "#008080", borderColor: "#008080" }}>+ Add Ingredient</Button>
          </div>
          <div className="sectionBody">
            <Table columns={ingredientColumns} dataSource={ingredients} rowKey="ingredientId" pagination={false} size="small" />
          </div>
        </div>

    {/* Preparation Steps */}
    <div className="section">
          <div className="sectionHeader">
            <h3>Preparation Process</h3>
            <Button type="primary" onClick={() => handleAddStep(setPreparationSteps, preparationSteps)} style={{ backgroundColor: "#008080", borderColor: "#008080" }}>+ Add Step</Button>
          </div>
          <Table columns={stepColumns(setPreparationSteps, preparationSteps)} dataSource={preparationSteps} rowKey="number" pagination={false} size="small" />
        </div>

        {/* Cooking Steps */}
        <div className="section">
          <div className="sectionHeader">
            <h3>Cooking Process</h3>
            <Button type="primary" onClick={() => handleAddStep(setCookingSteps, cookingSteps)} style={{ backgroundColor: "#008080", borderColor: "#008080" }}>+ Add Step</Button>
          </div>
          <Table columns={stepColumns(setCookingSteps, cookingSteps)} dataSource={cookingSteps} rowKey="number" pagination={false} size="small" />
        </div>

        {/* Plating Steps */}
        <div className="section">
          <div className="sectionHeader">
            <h3>Plating Process</h3>
            <Button type="primary" onClick={() => handleAddStep(setPlatingSteps, platingSteps)} style={{ backgroundColor: "#008080", borderColor: "#008080" }}>+ Add Step</Button>
          </div>
          <Table columns={stepColumns(setPlatingSteps, platingSteps)} dataSource={platingSteps} rowKey="number" pagination={false} size="small" />
        </div>

        {/* Section 4: Cost Summary */}
        <div className="section">
          <div className="sectionHeader">
            <h3>Cost Summary</h3>
          </div>
          <div className="sectionBody">
            <Form.Item label="Total Cost">
              <Input value={`$${totalCost.toFixed(2)}`} disabled />
            </Form.Item>
          </div>
        </div>
  
        {/* Submit Button */}
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading} style={{ backgroundColor: "#008080", borderColor: "#008080" }}>
            Save Recipe
          </Button>
        </Form.Item>
        
      </Form>
    </Drawer>
  );
};

export default AddRecipeForm;