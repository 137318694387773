import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification, Input, Select, DatePicker } from "antd";
import { AuthTokenType, product, UserProps, PriceAlertProps } from "../utils/types";
import { getAuthToken, getpricealerts, } from "../utils/functions";  // Ensure getPriceAlert is updated
import { useGetPricealert, useGetProductwithinventory, useGetUsers } from "../utils/hooks"; // Ensure useGetPriceAlert is implemented
import axios from "axios";
import ContentLayoutnew from "../components/ContentLayoutnew";
import dayjs from "dayjs"; 
import { pricealertUrl } from "../utils/network";

import delete1 from '../assets/delete.png'; // Import the logo image
import edit1 from '../assets/edit.png'; // Import the logo image
import action from '../assets/action.png'; // Import the logo image
import liquor from '../assets/liquor.png'; // Import the logo image
import save from '../assets/save.png'; // Import the logo image
import close1 from '../assets/close.png'; // Import the logo image

import status1 from '../assets/status.png'; // Import the logo image
import record from '../assets/record.png'; // Import the logo image
import maximum from '../assets/maximum.png'; // Import the logo image
import minimum from '../assets/minimum.png'; // Import the logo image


const PriceAlert: FC = () => {
  const [priceAlerts, setPriceAlerts] = useState<PriceAlertProps[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [newPriceAlert, setNewPriceAlert] = useState<any>({
    id: -1,
    sku_code: null,
    lower_threshold_value: "",
    upper_threshold_value: "",
  });
  const [editingPriceAlert, setEditingPriceAlert] = useState<any | null>(null);
  const [products, setProducts] = useState<product[]>([]);
  const [users, setUsers] = useState<UserProps[]>([]);

  useGetProductwithinventory(setProducts, setFetching);
  useGetPricealert(setPriceAlerts, setFetching);

  const handleDelete = (recordId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this Price Alert?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          await axios.delete(`${pricealertUrl}/${recordId}`, headers);
          getpricealerts(setPriceAlerts, setFetching);
          notification.success({ message: "Price Alert deleted successfully!" });
        } catch (error) {
          notification.error({ message: "Failed to delete Price Alert!" });
        }
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const handleDeleteSelected = async (ids: number[]) => {
    try {
      const headers = getAuthToken() as AuthTokenType;
      await Promise.all(ids.map((id) => axios.delete(`${pricealertUrl}/${id}`, headers)));
      getpricealerts(setPriceAlerts, setFetching);
      notification.success({ message: "Selected Price Alerts deleted successfully!" });
    } catch (error) {
      notification.error({ message: "Failed to delete selected Price Alerts!" });
    }
  };

  const handleAddRow = () => {
    setIsAdding(true);
    setNewPriceAlert({
      id: -1,
      sku_code: null,
      upper_threshold_value: "",
      lower_threshold_value: "",
    });
  };

  const handleSaveNewPriceAlert = async () => {
    try {
      const headers = getAuthToken() as AuthTokenType;
      const response = await axios.post(pricealertUrl, {
        sku_code_id: newPriceAlert.sku_code,
        lower_threshold_value: newPriceAlert.lower_threshold_value,
        upper_threshold_value: newPriceAlert.upper_threshold_value,
      }, headers);

      setPriceAlerts((prev) => [...prev, response.data]);
      setNewPriceAlert({
        id: -1,
        sku_code: null,
        lower_threshold_value: "",
        upper_threshold_value: "",
      });
      setIsAdding(false);
      notification.success({ message: "Price Alert added successfully!" });
    } catch (error) {
      notification.error({ message: "Failed to add Price Alert!" });
    }
  };

  const handleEdit = (record: any) => {
    setEditingPriceAlert(record);
  };

  const handleSaveEdit = async (priceAlert: any) => {
    try {
      const headers = getAuthToken() as AuthTokenType;
      await axios.put(`${pricealertUrl}/${priceAlert.id}`, {
        ...priceAlert,
        sku_code_id: priceAlert.sku_code.id, // Send the sku_code id (foreign key)
      }, headers);

      getpricealerts(setPriceAlerts, setFetching);
      setEditingPriceAlert(null);
      notification.success({ message: "Price Alert updated successfully!" });
    } catch (error) {
      notification.error({ message: "Failed to update Price Alert!" });
    }
  };

  const columns = [
    {
      title: () => (
        <span>
          <img src={liquor} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          SKU Name
        </span>
      ),
      dataIndex: "sku_code",
      key: "sku_code",
      render: (sku_code: product, record: any) => {
        if (isAdding && record.id === -1) {
          return (
            <Select
              value={newPriceAlert.sku_code}
              onChange={(value) => setNewPriceAlert({ ...newPriceAlert, sku_code: value })}
              placeholder="Select SKU Code"
              style={{ width: "100%" }}
            >
              {products.map((product) => (
                <Select.Option key={product.id} value={product.id}>
                  {product.name}
                </Select.Option>
              ))}
            </Select>
          );
        }
        if (editingPriceAlert && editingPriceAlert.id === record.id) {
          return (
            <Select
              value={editingPriceAlert.sku_code.id} // Show SKU Code ID
              onChange={(value) =>
                setEditingPriceAlert({
                  ...editingPriceAlert,
                  sku_code: products.find((product) => product.id === value) || editingPriceAlert.sku_code,
                })
              }
              placeholder="Select SKU Code"
              style={{ width: "100%" }}
            >
              {products.map((product) => (
                <Select.Option key={product.id} value={product.id}>
                  {product.name}
                </Select.Option>
              ))}
            </Select>
          );
        }
        return sku_code.name; // Display SKU Code when not editing
      },
    },
    {
      title: () => (
        <span>
          <img src={minimum} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Lower Threshold Value
        </span>
      ),
      dataIndex: "lower_threshold_value",
      key: "lower_threshold_value",
      render: (text: string, record: any) => {
        if (isAdding && record.id === -1) {
          return (
            <Input
              value={newPriceAlert.lower_threshold_value}
              onChange={(e) => setNewPriceAlert({ ...newPriceAlert, lower_threshold_value: e.target.value })}
              placeholder="Enter Lower threshold value"
            />
          );
        }
        if (editingPriceAlert && editingPriceAlert.id === record.id) {
          return (
            <Input
              value={editingPriceAlert.lower_threshold_value}
              onChange={(e) =>
                setEditingPriceAlert({
                  ...editingPriceAlert,
                  lower_threshold_value: e.target.value,
                })
              }
            />
          );
        }
        return text;
      },
    },

    {
      title: () => (
        <span>
          <img src={maximum} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Upper Threshold Value
        </span>
      ),
      dataIndex: "upper_threshold_value",
      key: "upper_threshold_value",
      render: (text: string, record: any) => {
        if (isAdding && record.id === -1) {
          return (
            <Input
              value={newPriceAlert.upper_threshold_value}
              onChange={(e) => setNewPriceAlert({ ...newPriceAlert, upper_threshold_value: e.target.value })}
              placeholder="Enter Upper threshold value"
            />
          );
        }
        if (editingPriceAlert && editingPriceAlert.id === record.id) {
          return (
            <Input
              value={editingPriceAlert.upper_threshold_value}
              onChange={(e) =>
                setEditingPriceAlert({
                  ...editingPriceAlert,
                  upper_threshold_value: e.target.value,
                })
              }
            />
          );
        }
        return text;
      },
    },
    {
      title: () => (
        <span>
          <img src={action} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Action
        </span>
      ),
      key: "actions",
      render: (text: string, record: any) => {
        if (isAdding && record.id === -1) {
          return (
            <span>
              <a onClick={handleSaveNewPriceAlert}>
                <img src={save} alt="Save" style={{ width: "20px", height: "20px", marginRight: "8px" }} />
              </a>
              <span> | </span>
              <a
                onClick={() => {
                  setIsAdding(false);
                  setNewPriceAlert({ sku_code: null, condition: "increase", threshold_value: "" });
                }}
              >
                <img src={close1} alt="Cancel" style={{ width: "20px", height: "20px", marginLeft: "8px" }} />
              </a>
            </span>
          );
        }
      
        if (editingPriceAlert && editingPriceAlert.id === record.id) {
          return (
            <span>
              <a onClick={() => handleSaveEdit(editingPriceAlert)}>
                <img src={save} alt="Save" style={{ width: "20px", height: "20px", marginRight: "8px" }} />
              </a>
              <span> | </span>
              <a onClick={() => setEditingPriceAlert(null)}>
                <img src={close1} alt="Cancel" style={{ width: "20px", height: "20px", marginLeft: "8px" }} />
              </a>
            </span>
          );
        }
      
        return (
          <span>
            <a onClick={() => handleEdit(record)}>
              <img src={edit1} alt="Edit" style={{ width: "20px", height: "20px", marginRight: "8px" }} />
            </a>
            <span> | </span>
            <a onClick={() => handleDelete(record.id)}>
              <img src={delete1} alt="Delete" style={{ width: "20px", height: "20px", marginLeft: "8px" }} />
            </a>
          </span>
        );
      }
    },
  ];

  const dataSource = isAdding
    ? [
        ...priceAlerts,
        {
          id: -1,
          sku_code: { id: newPriceAlert.sku_code, name: "" },
          lower_threshold_value: newPriceAlert.lower_threshold_value,
          upper_threshold_value: newPriceAlert.upper_threshold_value,
        },
      ]
    : priceAlerts;

  return (
    <ContentLayoutnew
      pageTitle="Price Alerts"
      buttontitle="Add Price Alert"
      setModalState={() => {}}
      dataSource={dataSource as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
      onAddRow={handleAddRow}
      onDeleteSelected={handleDeleteSelected}
      />
    );
  };
  
  export default PriceAlert;