import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification, Tag } from "antd";
import { useGetCategory } from "../utils/hooks";
import { AuthTokenType, category } from "../utils/types";
import { getAuthToken, getCategory } from "../utils/functions";
import AddCategoryForm from "../components/AddCategoryForm";
import axios from "axios";
import { categoriesUrl } from "../utils/network";
import ContentLayout from "../components/ContentLayout";
import name from '../assets/name.png'; // Import the logo image

import delete1 from '../assets/delete.png'; // Import the logo image
import edit1 from '../assets/edit.png'; // Import the logo image
import action from '../assets/action.png'; // Import the logo image
import role from '../assets/role.png'; // Import the logo image
import type1 from '../assets/type.png'; // Import the logo image



const Categories: FC = () => {
  const [filterValues, setFilterValues] = useState<{ [key: string]: string[] }>({});
  
  const columns = [

    {
      title: () => (
        <span>
          <img src={name} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Name
        </span>
      ),

      dataIndex: "name",
      key: "name",
    },
    {
      title: () => (
        <span>
          <img src={role} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Is Parent
        </span>
      ),
      dataIndex: "is_parent",
      key: "is_parent",
      render: (isActive: boolean) => (
        <Tag color={isActive ? "green" : "red"}>
          {isActive ? "Yes" : "No"}
        </Tag>
      ),
    },

    {
      title: () => (
        <span>
          <img src={type1} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Parent
        </span>
      ),
      dataIndex: 'par',
      key: 'par',
    },
   
    {
      title: () => (
        <span>
          <img src={action} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Action
        </span>
      ),
      key: "actions",
      render: (text: string, record: category) => (
        <span>
          <a onClick={() => handleEdit(record)}> <img src={edit1} alt="Save" style={{ width: "20px", height: "20px", marginRight: "8px" }} />
          </a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}><img src={delete1} alt="Save" style={{ width: "20px", height: "20px", marginRight: "8px" }} />            
          </a>
        </span>
      ),
    },
  ];

  const [categories, setCategories] = useState<category[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingCategory, setEditingCategory] = useState<category | null>(null);


  useGetCategory(setCategories, setFetching);


  
  useEffect(() => {
    // When editingCategory changes, open the modal
    if (editingCategory) {
      setDrawerVisible(true);
    }
  }, [editingCategory]);

  const onCloseWithoutEditing = () => {
    setEditingCategory(null); // Reset editingCategory when form is closed without editing
  };

  const handleEdit = (record: category) => {
    setEditingCategory(record);
  };
  const handleDelete = (categoryId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this category?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          // Make a DELETE request to the API endpoint for deleting a category
          await axios.delete(`${categoriesUrl}/${categoryId}/`, headers);
  
          // After successful deletion, fetch updated categories
          getCategory(setCategories, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: "Delete Operation Error",
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  return (
    <ContentLayout
      pageTitle="Product Categories"
      buttontitle="Add Category"
      setModalState={setDrawerVisible}
      dataSource={categories as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}

    >
      <AddCategoryForm
        onSuccessCallBack={() => {
          setDrawerVisible(false);
          getCategory(setCategories, setFetching);
        }}
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        editingCategory={editingCategory}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </ContentLayout>
  );
};

export default Categories;