import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button, DatePicker, Select } from "antd";
import { AddWasteLogpropsFormProps, AuthTokenType,  DataProps,  product, UserProps } from "../utils/types";
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { useGetProductwithinventory, useGetUsers } from "../utils/hooks";
import { WastelogUrl } from "../utils/network";

const AddWasteLogForm: FC<AddWasteLogpropsFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingWastelog,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<product[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);

  const [selectedUOM, setSelectedUOM] = useState<string | null>(null);

  useGetProductwithinventory(setProducts, setLoading);

  const [users, setUsers] = useState<UserProps[]>([]); // For reported by dropdown

  // Fetch products and users
  useGetUsers(setUsers, setFetching);


  useEffect(() => {
    if (editingWastelog) {
      setSelectedUOM(editingWastelog.sku_code.sku_uom.name)
      form.setFieldsValue({
        ...editingWastelog,
        sku_code_id: editingWastelog.sku_code.id,
        date: editingWastelog.date ? dayjs(editingWastelog.date) : null,
      });
    } else {
      form.resetFields();
    }
  }, [editingWastelog, form]);

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let response: AxiosResponse;
      if (editingWastelog) {
        response = await axios.put(`${WastelogUrl}/${editingWastelog.id}`, values, headers);
      } else {
        response = await axios.post(WastelogUrl , values, headers);
      }

      setLoading(false);
      if (response) {
        form.resetFields();
        onSuccessCallBack?.();
        onClose?.();
      }
    } catch (error) {
      notification.error({ message: "Operation Error" });
      setLoading(false);
    }
  };

  return (
    <Drawer
      title={editingWastelog ? "Edit Waste Log" : "Add Waste Log"}
      visible={isVisible}
      onClose={onClose}
      destroyOnClose
      width={360}
    >
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Form.Item
          label="SKU Code"
          name="sku_code_id"
          
          rules={[{ required: true, message: "Please select an SKU code!" }]
        }
        >
          <Select placeholder="Select SKU Code"
           onChange={(value) => {
            const selectedProduct = products.find(p => p.id === value);
            setSelectedUOM(selectedProduct?.sku_uom?.name || null); // Update UOM
          }}>
            {products.map((product) => (
              <Select.Option key={product.id} value={product.id}>
                {product.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Date"
          name="date"
          rules={[{ required: true, message: "Please select a date!" }]}
        >
          <DatePicker format="MM-DD-YYYY" />
        </Form.Item>

        <Form.Item
          label="Reason"
          name="reason"
          rules={[{ required: true, message: "Please enter a reason!" }]}
        >
          <Input placeholder="Reason for waste" />
        </Form.Item>
        <Form.Item
          label="Wasted By"
          name="wasted_by"
          rules={[{ required: true, message: "Please enter who wasted the item!" }]}
        >
          <Input placeholder="Wasted By" />
        </Form.Item>

        <Form.Item
          label="Reported By"
          name="reported_by"
          rules={[{ required: true, message: "Please select who reported the waste!" }]}
        >
          <Select placeholder="Select Reporter">
            {users.map((user) => (
              <Select.Option key={user.id} value={user.fullname}>
                {user.fullname}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Quantity"
          name="quantity"
          rules={[{ required: true, message: "Please enter Quantity!" }]}
        >
          <Input type="number" placeholder="Waste Quantity"
          suffix={selectedUOM ? ` ${selectedUOM}` : ""} />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" type="primary" block loading={loading}>
            {editingWastelog ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddWasteLogForm;