import React, { FC } from "react";
import { Drawer, Button, Descriptions, Table, Typography, Divider } from "antd";
import { Equipment, product, Recipeprops } from "../utils/types";

const { Title, Text } = Typography;

interface RecipeViewProps {
  isVisible: boolean;
  onClose: () => void;
  recipe: Recipeprops | null;
}

const RecipeViewDrawer: FC<RecipeViewProps> = ({ isVisible, onClose, recipe }) => {
  if (!recipe) return null;

  const equipmentColumns = [
    {
      title: "Name",
      dataIndex: "equipment",
      key: "equipment",
      render: (equip: Equipment) => equip.name,
    },
  ];

  const ingredientColumns = [
    {
      title: "Name",
      dataIndex: "ingredient",
      key: "ingredient",
      render: (ingredient: product) => ingredient.name,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity: number) => `${quantity} units`,
    },
    

  ];

  const processColumns = [
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      render: (value: number) => Math.round(value),
    },
    {
      title: "Instruction",
      dataIndex: "step",
      key: "step",
    },
  ];


  return (
    <Drawer
      title={<Title level={2} style={{ color: "#008080", marginBottom: 0 }}>Recipe Details</Title>}
      visible={isVisible}
      onClose={onClose}
      destroyOnClose
      width={800}
      bodyStyle={{ padding: "20px", backgroundColor: "#f5f7fa" }}
    >
   {/* Recipe Name */}
   <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
        <Title level={3} style={{ color: "#008080" }}>{recipe.name}</Title>
        <Title level={3} style={{ color: "#008080" }}>{recipe.recipeid}</Title>
      </div>
{/* Description & Image Section */}
<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
  {/* Description (Smaller Width - 40%) */}
  <div style={{ flex: 0.4, paddingRight: "20px" }}>
    <Text style={{ fontSize: "16px", color: "#666" }}>
      {recipe.description || "No description provided."}
    </Text>
  </div>

  {/* Image (Larger Width - 60%) */}
  {recipe.image && (
    <div style={{ flex: 0.6, display: "flex", justifyContent: "center" }}>
      <img
        src={recipe.image}
        alt="Recipe"
        style={{
          width: "100%",
          maxWidth: "350px",
          height: "250px",
          objectFit: "cover",
          borderRadius: "12px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
          border: "2px solid #008080",
        }}
      />
    </div>
  )}
</div>

      <Divider />

      {/* Bottom Section (Equipment & Ingredients Side by Side) */}
      <div className="recipeviewer-bottom-section">
        {/* Equipment Section */}
        <div className="recipeviewer-section">
          <div className="section-header">
            <Title level={4} style={{ margin: "0", padding: "0" }}>Equipment</Title>
          </div>
          <Table
            columns={equipmentColumns}
            dataSource={recipe.equipments}
            rowKey={(equip) => equip.equipment.id}
            pagination={false}
            size="small"
            style={{ marginTop: "10px" }}
          />
        </div>

        {/* Ingredients Section */}
        <div className="recipeviewer-section">
          <div className="section-header">
            <Title level={4} style={{ margin: "0", padding: "0" }}>Ingredients</Title>
          </div>
          <Table
            columns={ingredientColumns}
            dataSource={recipe.ingredients}
            rowKey={(ingredient) => ingredient.ingredient.id}
            pagination={false}
            size="small"
            style={{ marginTop: "10px" }}
          />
        </div>
      </div>

      <Divider />

      {/* Cooking Details Section (now at the bottom) */}
      <div className="recipeviewer-section">
          <div className="section-header">

      <Title level={4} style={{ margin: "0", padding: "0" }}>Cooking Details</Title>
      </div>

        <Descriptions bordered column={2} size="small">
          <Descriptions.Item label="Prep Time">{recipe.preparation_time} mins</Descriptions.Item>
          <Descriptions.Item label="Cooking Time">{recipe.cooking_time} mins</Descriptions.Item>
        </Descriptions>
      </div>

      <Divider />
      <div className="recipeviewer-section">
          <div className="section-header">

  <Title level={4} style={{ margin: "0", padding: "0" }}>Preparation Process</Title>
  </div>

      <Table
        columns={processColumns}
        dataSource={recipe.preparation}
        rowKey={(step) => step.step}
        pagination={false}
        size="small"
        
      />
</div>
      <Divider />

      <div className="recipeviewer-section">
          <div className="section-header">

      <Title level={4} style={{ margin: "0", padding: "0" }}>Cooking Process</Title>
      </div>

      <Table
        columns={processColumns}
        dataSource={recipe.cooking}
        rowKey={(step) => step.step}
        pagination={false}
        size="small"
      />
</div>

      <Divider />

      <div className="recipeviewer-section">
          <div className="section-header">

      <Title level={4} style={{ margin: "0", padding: "0" }}>Plating Process</Title>
      </div>

      <Table
        columns={processColumns}
        dataSource={recipe.plating}
        rowKey={(step) => step.step}
        pagination={false}
        size="small"
      />
</div>

      <Divider />

      {/* Close Button */}
      <Button type="primary" onClick={onClose} block className="recipeviewer-close-button">
        Close
      </Button>
    </Drawer>
  );
};

export default RecipeViewDrawer;
