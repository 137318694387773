import { useState, ChangeEvent } from "react"
import {getAuthToken, getpurchase} from "../utils/functions"
import {AuthTokenType,purchaseProps} from "../utils/types"
import { useGetpurchase} from "../utils/hooks"
import { Button,Drawer,Input, Modal, Table, Tag, notification } from "antd"
import axios,{AxiosResponse} from "axios"
import { onhandUrl, purchaseorderUrl, sendpoUrl, viewpoUrl} from "../utils/network"
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import EmailIcon from "@mui/icons-material/Email";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import FileSaver from "file-saver"; // Import FileSaver.js library
import EditPurchaseOrderDrawer from "../components/EditPurchaseOrderDrawer"
import dayjs from "dayjs";



const formatpurchaseDataProps = (
  purchasedata: purchaseProps[],
  onCloseOrder: (purchaseid: purchaseProps) => void,
  onChangeAdd: (value: Date | null, purchase_id: number) => void,
  onViewDetails: (item: purchaseProps) => void,
  onDeleteOrder: (item: purchaseProps) => void,
  onEditOrder: (item: purchaseProps) => void,

) => {
  return purchasedata.map((item) => {
    let actionLabel = '';
    let actionHandler = () => {};

    if (item.status === 'Open') {
      actionLabel = ('Close'); // Translate the action label
      actionHandler = () => onCloseOrder(item);
    } 

    const changeDeliveryDateInput = item.status === 'Open' ? (
      <div>
        <Input
          style={{ width: "150px" }}
          type="date"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeAdd(e.target.valueAsDate, item.id)
          }
        />
      </div>
    ) : null;

    return {
      ...item,
      key: item.id,
      change_delivery_date: changeDeliveryDateInput,
      action: (
        <div>
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={actionHandler}
          >
            {actionLabel}
          </span>{" "}
          |{" "}
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => onViewDetails(item)}
          >
            {('Details')} {/* Translate the action */}
          </span>{" "}
          |{" "}
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => onDeleteOrder(item)}
          >
            {('Delete')} {/* Translate the action */}
          </span>
          |{" "} {/* Add Edit button */}
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => onEditOrder(item)} // Call onEditOrder function on click
          >
            {('Edit')} {/* Translate the action */}
          </span>
        </div>
      ),
    };
  });
};

function PurchaseOrderReview(){
  

  const[fetching,setFetching]=useState(true)
    
  const [purchase,setpurchase]=useState<purchaseProps[]>([])
  const [purchaseadd,setpurchaseadd]=useState<{[key:number]:Date|null}>([])
  useGetpurchase(setpurchase,setFetching)
  const [loading,setLoading]=useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState<purchaseProps | null>(null);
  const [speedDialOpen, setSpeedDialOpen] = useState(false);

  const [editDrawerVisible, setEditDrawerVisible] = useState(false);
  const [editingItem, setEditingItem] = useState<purchaseProps | null>(null);


  const onEditOrder = (item: purchaseProps) => {
    setEditingItem(item);
    setEditDrawerVisible(true);
  };

  const showModal = (item: purchaseProps) => {
    setCurrentItem(item);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };



  const sendemail= async (purchaseorder:purchaseProps|any)=>{

    const datatosend={
      purchase_order_id:purchaseorder.id,
    }
  
      const headers=getAuthToken() as AuthTokenType
      
  
    const response:AxiosResponse =await axios.post(sendpoUrl,datatosend,headers).catch(
        (e)=>{ notification.error({
            message:"Operation Error",
            description:e.response?.data.error
        })
  
    }
        ) as AxiosResponse
  
        setLoading(false)
  
        if(response){
            notification.success({
                message:"Operation Success",
                description:"Email Sent Successfully"
                
            })
  
        }
      }


  
      const downloadpo= async (purchaseorder:purchaseProps|any)=>{

        const datatosend={
          purchase_order_id:purchaseorder.id,
        }
      
          const headers=getAuthToken() as AuthTokenType
          
      
        const response:AxiosResponse =await axios.post(viewpoUrl,datatosend,headers).catch(
            (e)=>{ notification.error({
                message:"Operation Error",
                description:e.response?.data.error
            })
      
        }
            ) as AxiosResponse
          const pdfBlob = new Blob([response.data], { type: "application/pdf" });
          FileSaver.saveAs(pdfBlob, `Purchase_Order_${datatosend.purchase_order_id}.pdf`);
                     setLoading(false)
      
          }
    

          const uniqueSuppliers = Array.from(new Set(purchase.map(item => item.from_location ? item.from_location.name : 'N/A')));
          const uniqueStatuses = Array.from(new Set(purchase.map(item => item.status)));


      const purchaseordercolumns = [
        
          {
            title: ('Purchase Order'),
            dataIndex: 'po_number',
            key: 'po_number',
          },
          {
            title: ('Supplier'),
            dataIndex: 'origin',
            key: 'origin',
            filters: uniqueSuppliers.map(item => ({
              text: item,
              value: item
            })),
            onFilter: (value:any, record:any) => record.from_location.name.toLowerCase().includes(value.toLowerCase()),
          },
          
          
          {
            title: ('Delivery Date'),
            dataIndex: 'expected_delivery_date',
            key: 'expected_delivery_date',
            render: (text: string) => <div style={{ width: "10vw" }}>{text}</div> 

          },
          {
            title: ('Actual Delivery Date'),
            dataIndex: 'actual_delivery_date',
            key: 'actual_delivery_date',
            render: (text: string) => <div style={{ width: "10vw" }}>{text}</div> 

          },

          {
            title: ('Warehouse'),
            dataIndex: 'destination',
            key: 'destination',

          },

          {
            title: ('Shipping Company'),
            dataIndex: 'shipping_company',
            key: 'shipping_company',
          },

          {
            title: ('Status'),
            dataIndex: 'status',
            key: 'status',
            filters: uniqueStatuses.map(item => ({
              text: item,
              value: item
            })),
            onFilter: (value:any, record:any) => record.status.toLowerCase().includes(value.toLowerCase()),
          },
          {
            title: ('Change Delivery Date'),
            dataIndex: 'change_delivery_date',
            key: 'change_delivery_date',
          },

          {
            title: ('Actions'),
            dataIndex: 'action',
            key: 'action',
            render: (text: string) => <div style={{ width: "20vw" }}>{text}</div> 
          },
        ];

        const downloadCSV = () => {
          // Convert data to CSV format
          const csvContent = purchaseordercolumns.map(column => column.title).join('\t') + '\n' +
            filteredData.map(row => purchaseordercolumns.map(column => {
              return `"${row[column.dataIndex as keyof purchaseProps]}"`;
            }).join('\t')).join('\n');
        
          // Create a Blob object containing the CSV content
          const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        
          // Create a temporary URL for the Blob
          const url = window.URL.createObjectURL(blob);
        
          // Create a link element
          const link = document.createElement("a");
          link.setAttribute("href", url);
          link.setAttribute("download", "data.csv");
        
          // Append the link to the document body and trigger the click event
          document.body.appendChild(link);
          link.click();
        
          // Clean up by revoking the temporary URL
          window.URL.revokeObjectURL(url);
        };

        
        
        const Closeorder = async (purchaseorder: purchaseProps | any) => {
          Modal.confirm({
            title: "Confirm Close Order",
            content: "Are you sure you want to close this order?",
            onOk: async () => {
              let statusToUpdate = "";
        
              if (purchaseorder.status === "Open") {
                statusToUpdate = "Closed";
                // Update inventory only if transitioning from 'Intransit' to 'Closed'
                for (let i = 0; i < purchaseorder.purchaseorder_items.length; i++) {
                  const datatosend2inv = {
                    sku_code_id: purchaseorder.purchaseorder_items[i].item.id,
                    storage_location_id: purchaseorder.to_location.id,
                    status: "InStock",
                    reason: "Stock Received",
                    totalunits: purchaseorder.purchaseorder_items[i].quantity,
                    changedunits: purchaseorder.purchaseorder_items[i].quantity,
                  };
        
                  const headers = getAuthToken() as AuthTokenType;
                  await axios
                    .post(onhandUrl, datatosend2inv, headers)
                    .catch((e) => {
                      notification.error({
                        message: "Operation Error",
                        description: e.response?.data.error,
                      });
                    });
                }
              }
        
              const datatosend: any = {
                id: purchaseorder.id,
                status: statusToUpdate,
              };
        
              if (statusToUpdate === "Closed") {
                datatosend.actual_delivery_date = purchaseadd[purchaseorder.id];
              }
        
              setLoading(true);
              const headers = getAuthToken() as AuthTokenType;
        
              const response: AxiosResponse = await axios
                .patch(`${purchaseorderUrl}/${datatosend.id}`, datatosend, headers)
                .catch((e) => {
                  notification.error({
                    message: "Operation Error",
                    description: e.response?.data.error,
                  });
                }) as AxiosResponse;
        
              setLoading(false);
        
              if (response) {
                notification.success({
                  message: "Operation Success",
                  description:
                    "Order " +
                    (statusToUpdate === "Closed" ? "Closed" : "Updated to Intransit") +
                    " Successfully",
                });
              }
        
              getpurchase(setpurchase, setFetching);
            },
          });
        };
        
        const handleMailTo = () => {
          if (!currentItem) return;
        
          const recipient = currentItem.from_location?.email || "default@example.com"; // Replace with the actual recipient email address
          const subject = `TolHouse Order`;

          // Construct the email body based on the Django template
          const emailBody = `
Dear ${currentItem.from_location?.name},
            
Please find attached Purchase Order for TolHouse.
            
Purchase Order Details:
Expected Delivery Date: ${currentItem.expected_delivery_date}
Status: ${currentItem.status}
PO Number: ${currentItem.po_number}
Shipping Company: ${currentItem.shipping_company}

Purchase Order Items:
${currentItem.purchaseorder_items?.map(item => `
Item: ${item.item.name}
Quantity: ${item.quantity}
            `).join('')}
            
If you have any questions regarding this order, feel free to contact Will Lucas at will@tolhouse.com.
            
Regards,
Purchasing Manager
TolHouse
            
TolHouse
1447 N. Summit St.
Toledo, OH 43604
          `;
        
          // Create a temporary element to render the email body with HTML
          const tempElement = document.createElement('div');
          tempElement.innerHTML = emailBody;
        
          // Get the innerHTML of the temporary element, which will contain correctly rendered <br> tags
          const formattedEmailBody = tempElement.innerHTML;
        
          const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(formattedEmailBody)}`;
          window.location.href = mailtoLink;
        };
        const DeleteOrder = async (purchaseorder: purchaseProps | any) => {
          const confirmDeletion = () => {
            Modal.confirm({
              title: ("Confirm Deletion"),
              content: ("Are you sure you want to delete this order?"),
              onOk: async () => {
                try {
                  const datatosend = {
                    id: purchaseorder.id,
                  };
        
                  const headers = getAuthToken() as AuthTokenType;
        
                  const response: AxiosResponse = await axios.delete(purchaseorderUrl + '/' + datatosend.id, headers );
        
                  setLoading(false);
        
                  if (response) {
                    notification.success({
                      message: ("Operation Success"),
                      description: ("Order Deleted Successfully")
                    });
                  }
        
                  getpurchase(setpurchase, setFetching);
                } catch (error) {
                  notification.error({
                    message: ("Operation Error"),
                  });
                }
              },
              onCancel: () => {
                // Do nothing if the user cancels the deletion
              },
              okText: ("Yes"),
              cancelText: ("No"),
            });
          };
        
          confirmDeletion();
        };

        const onCloseWithoutEditing = () => {
          setEditingItem(null); // Reset editingCategory when form is closed without editing
        };
      
             
  const onChangeAdd=(value:Date|null,purchase_id:number)=>{
          setpurchaseadd({
            ...purchaseadd,
            [purchase_id]:value
          })

        }
        const [searchQuery, setSearchQuery] = useState("");

        const filteredData = purchase.filter((record) =>
        Object.values(record).some((value) =>
          value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      );

        return (
          <>
              <EditPurchaseOrderDrawer
              isVisible={editDrawerVisible}
              onClose={() => setEditDrawerVisible(false)}
              editingPurchase={editingItem}
              onSuccessCallBack={() => {
                setEditDrawerVisible(false);
                getpurchase(setpurchase, setFetching);

              }}
              onCloseWithoutEditing={onCloseWithoutEditing}

            />
          <div className="card">
              <div className="cardHeader">
                  <h2 className="headContent">{("Purchase Orders")}</h2>
                  </div>
                  <div className="rightContent">
                  <Input
              placeholder="Search..."
              onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
              style={{ marginRight: '10px', height: "40px", width: "200px", }}
            />
            <Button onClick={downloadCSV}>Download</Button>
              </div>
              <br/>
              <Table className="custom-table"
              dataSource={(formatpurchaseDataProps(purchase, Closeorder, onChangeAdd, showModal, DeleteOrder, onEditOrder, )).filter(record =>
                Object.values(record).some(value =>
                  value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
                ))}                
                columns={purchaseordercolumns as any}
              size="small"
              loading ={fetching}/>

<Drawer
  title="Details"
  visible={isModalVisible}
  placement="right"
  onClose={handleOk}
  style={{ borderRadius: '10px' }} // Add rounded corners to the drawer
>
  {currentItem && (
    <>
      <div style={{ marginBottom: '20px', borderBottom: '1px solid #e8e8e8', paddingBottom: '10px' }}>
        <p style={{ fontSize: '16px', marginBottom: '8px', fontWeight: 'bold' }}>ID: {currentItem.id}</p>
        <p style={{ fontSize: '16px', marginBottom: '8px' }}>Supplier: {currentItem.from_location?.name}</p>
        <p style={{ fontSize: '16px', marginBottom: '8px' }}>To Location: {currentItem.to_location?.name}</p>
      </div>
      <div>
        <p style={{ fontSize: '16px', marginBottom: '8px', fontWeight: 'bold' }}>Items:</p>
        <Table
          className="custom-table"
          dataSource={currentItem.purchaseorder_items}
          columns={[
            {
              title: ('Item Name'),
              dataIndex: ['item', 'name'],
              key: 'name',
              render: (text: string) => <span>{JSON.stringify(text)}</span>,
            },
            {
              title: ('Quantity'),
              dataIndex: 'quantity',
              key: 'quantity',
              render: (text: number) => <span>{JSON.stringify(text)}</span>,
            },
          ]}
          pagination={false}
          bordered
          size="small"
          style={{ marginTop: '20px' }}
        />
      </div>
    </>
  )}
   <SpeedDial
            ariaLabel="SpeedDial"
            icon={<SpeedDialIcon />}
            onClose={() => setSpeedDialOpen(false)}
            onOpen={() => setSpeedDialOpen(true)}
            open={speedDialOpen}
            direction="up"
            FabProps={{ size: 'small' }} 
          >
 
  <SpeedDialAction
    key="Download"
    icon={<PictureAsPdfIcon />}
    tooltipTitle="Download "
    onClick={() => downloadpo(currentItem)}
  /> <SpeedDialAction
  key="mailTo"
  icon={<AlternateEmailIcon />}
  tooltipTitle="Mail To"
  onClick={handleMailTo}
/>
          </SpeedDial>
</Drawer>
          </div>
       
        </> 
        )
    }
    
    export default PurchaseOrderReview