import React, { useEffect, useState } from "react";
import { Table, Spin, notification, Row, Col, Card } from "antd";
import { useGetwastesummary } from "../utils/hooks";

const Wastesummary = () => {
  const [data, setData] = useState<any>({});
  const [isFetching, setFetching] = useState(false);

  // Fetch waste summary data using the custom hook
  useGetwastesummary(setData, setFetching);

  // Define columns for the top products table
  const topProductsColumns = [
    {
      title: "SKU Name",
      dataIndex: "sku_code__name",
      key: "sku_code__name",
    },
    {
      title: "Amount Wasted",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (amount: number) => `$${amount.toFixed(2)}`,
    },
  ];

  // Define columns for the top reasons table
  const topReasonsColumns = [
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Total Amount Wasted",
      dataIndex: "total_waste",
      key: "total_waste",
      render: (quantity: number) => quantity.toFixed(2),
    },
  ];

  return (
    <>
      <div className="card">
        <div className="borderline">
          <div className="cardHeader">
            <h2 className="headContent">Waste Summary</h2>
          </div>
          <div className="cardContent">
            <Row gutter={16}>
              {/* First Card for Top Products Table */}
              <Col span={12}>
                <Card
                  title="Products"
                  className="custom-card"
                  headStyle={{
                    backgroundColor: "transparent", // No background color
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "black", // Black text color
                    borderBottom: "2px solid #d9d9d9", // Subtle bottom border
                    padding: "12px 16px",
                  }}
                >
                  {isFetching ? (
                    <Spin size="large" />
                  ) : (
                    <Table
                      className="custom-table"
                      dataSource={data.top_products}
                      columns={topProductsColumns}
                      rowKey="sku_code__name"
                      pagination={false}
                    />
                  )}
                </Card>
              </Col>

              {/* Second Card for Top Reasons Table */}
              <Col span={12}>
                <Card
                  title="Reasons"
                  className="custom-card"
                  headStyle={{
                    backgroundColor: "transparent", // No background color
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "black", // Black text color
                    borderBottom: "2px solid #d9d9d9", // Subtle bottom border
                    padding: "12px 16px",
                  }}
                >
                  {isFetching ? (
                    <Spin size="large" />
                  ) : (
                    <Table
                      className="custom-table"
                      dataSource={data.top_reasons}
                      columns={topReasonsColumns}
                      rowKey="reason"
                      pagination={false}
                    />
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wastesummary;