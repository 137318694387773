import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification, Input, Select, DatePicker, Space } from "antd";
import { AuthTokenType, BillPaymentProps, product, supplier } from "../utils/types";
import { getAuthToken, getbillpayment, } from "../utils/functions";
import {  useGetbillpayment, useGetProductwithinventory, useGetSupplier } from "../utils/hooks";
import axios from "axios";
import ContentLayoutnew from "../components/ContentLayoutnew";
import { billpaymentUrl } from "../utils/network";
import dayjs from 'dayjs'; // Import dayjs
import delete1 from '../assets/delete.png'; // Import the logo image
import edit1 from '../assets/edit.png'; // Import the logo image
import action from '../assets/action.png'; // Import the logo image
import liquor from '../assets/liquor.png'; // Import the logo image
import supplier1 from '../assets/delivery.png'; // Import the logo image
import classification from '../assets/classification.png'; // Import the logo image
import close1 from '../assets/close.png'; // Import the logo image
import save from '../assets/save.png'; // Import the logo image
import pdf from '../assets/pdf.png'; // Import the logo image


import amount from '../assets/finance.png'; // Import the logo image
import date from '../assets/date.png'; // Import the logo image





const BillPayments: FC = () => {
  const [billPayments, setBillPayments] = useState<BillPaymentProps[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [newBillPayment, setNewBillPayment] = useState<any>({
    id: -1,
    supplier: null,
    sku_code: null,
    bill_type: "Material", // Default bill type
    description: "",
    amount: 0,
    payment_date: "",
    file: null, // New file state
  });
  const [editingBillPayment, setEditingBillPayment] = useState<any | null>(null);
  const [products, setProducts] = useState<product[]>([]);
  const [suppliers, setSuppliers] = useState<supplier[]>([]);

  useGetProductwithinventory(setProducts, setFetching);
  useGetSupplier(setSuppliers, setFetching);
  useGetbillpayment(setBillPayments, setFetching);

  

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setNewBillPayment({ ...newBillPayment, file: event.target.files[0] });
    }
  };

  const handleDelete = (recordId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this Bill Payment?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          await axios.delete(`${billpaymentUrl}/${recordId}`, headers);
          getbillpayment(setBillPayments, setFetching);
          notification.success({ message: "Bill Payment deleted successfully!" });
        } catch (error) {
          notification.error({ message: "Failed to delete Bill Payment!" });
        }
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const handleDeleteSelected = async (ids: number[]) => {
    try {
      const headers = getAuthToken() as AuthTokenType;
      await Promise.all(ids.map((id) => axios.delete(`${billpaymentUrl}/${id}`, headers)));
      getbillpayment(setBillPayments, setFetching);
      notification.success({ message: "Selected Bill Payments deleted successfully!" });
    } catch (error) {
      notification.error({ message: "Failed to delete selected Bill Payments!" });
    }
  };

  const handleAddRow = () => {
    setIsAdding(true);
    setNewBillPayment({
      id: -1,
      supplier: null,
      sku_code: null,
      bill_type: "Material", // Default bill type
      description: "",
      amount: 0,
      payment_date: "",
      file: null,
    });
  };
  

  const handleSaveNewBillPayment = async () => {
    try {
      const headers = {
        ...getAuthToken(),
        "Content-Type": "multipart/form-data",
      };

      const formData = new FormData();
      formData.append("supplier_id", newBillPayment.supplier);
      formData.append("sku_code_id", newBillPayment.sku_code);
      formData.append("bill_type", newBillPayment.bill_type);
      formData.append("description", newBillPayment.description);
      formData.append("amount", newBillPayment.amount);
      formData.append("payment_date", newBillPayment.payment_date);
      if (newBillPayment.file) {
        formData.append("file", newBillPayment.file);
      }

      const response = await axios.post(billpaymentUrl, formData,  headers );

      setBillPayments((prev) => [...prev, response.data]);
      setNewBillPayment({
        id: -1,
        supplier: null,
        sku_code: null,
        bill_type: "Material",
        description: "",
        amount: 0,
        payment_date: "",
        file: null,
      });
      setIsAdding(false);
      notification.success({ message: "Bill Payment added successfully!" });
    } catch (error) {
      notification.error({ message: "Failed to add Bill Payment!" });
    }
  };

  const handleEdit = (record: any) => {
    setEditingBillPayment(record);
  };

  const handleSaveEdit = async (billPayment: any) => {
    try {
      const headers = {
        ...getAuthToken(),
        "Content-Type": "multipart/form-data",
      };

      const formData = new FormData();
      formData.append("supplier_id", billPayment.supplier.id);
      formData.append("sku_code_id", billPayment.sku_code.id);
      formData.append("bill_type", billPayment.bill_type);
      formData.append("description", billPayment.description);
      formData.append("amount", billPayment.amount);
      formData.append("payment_date", billPayment.payment_date);
      if (billPayment.file) {
        formData.append("file", billPayment.file);
      }

      await axios.put(`${billpaymentUrl}/${billPayment.id}`, formData, headers );

      getbillpayment(setBillPayments, setFetching);
      setEditingBillPayment(null);
      notification.success({ message: "Bill Payment updated successfully!" });
    } catch (error) {
      notification.error({ message: "Failed to update Bill Payment!" });
    }
  };


  const columns = [
    {
      title: () => (
        <span>
          <img src={supplier1} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Supplier
        </span>
      ),
      dataIndex: "supplier",
      key: "supplier",
      sorter: (a: any, b: any) => a.supplier.name.localeCompare(b.supplier.name),
      render: (supplier: supplier, record: any) => {
        if (record.id === -1) {
          return (
            <Select
              value={newBillPayment.supplier}
              onChange={(value) => setNewBillPayment({ ...newBillPayment, supplier: value })}
              placeholder="Select Supplier"
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) => {
                if (!option) return false;
                const optionText = option.children as unknown as string;
                return optionText.toLowerCase().includes(input.toLowerCase());
              }}
            >
              {suppliers.map((supplier) => (
                <Select.Option key={supplier.id} value={supplier.id}>
                  {supplier.name}
                </Select.Option>
              ))}
            </Select>
          );
        }
        if (editingBillPayment && editingBillPayment.id === record.id) {
          return (
            <Select
              value={editingBillPayment.supplier.id} // Show Supplier ID
              showSearch
              filterOption={(input, option) => {
                if (!option) return false;
                const optionText = option.children as unknown as string;
                return optionText.toLowerCase().includes(input.toLowerCase());
              }}
              onChange={(value) =>
                setEditingBillPayment({
                  ...editingBillPayment,
                  supplier: suppliers.find((supplier) => supplier.id === value) || editingBillPayment.supplier,
                })
              }
              placeholder="Select Supplier"
              style={{ width: "100%" }}
            >
              {suppliers.map((supplier) => (
                <Select.Option key={supplier.id} value={supplier.id}>
                  {supplier.name}
                </Select.Option>
              ))}
            </Select>
          );
        }
        return supplier.name; // Display Supplier name when not editing
      },
    },
    {
      title: () => (
        <span>
          <img src={liquor} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          SKU Name
        </span>
      ),
      dataIndex: "sku_code",
      key: "sku_code",
      sorter: (a: any, b: any) => a.sku_code.name.localeCompare(b.sku_code.name),
      render: (sku_code: product, record: any) => {
        if (record.id === -1) {
          return (
            <Select
            showSearch
            filterOption={(input, option) => {
              if (!option) return false;
              const optionText = option.children as unknown as string;
              return optionText.toLowerCase().includes(input.toLowerCase());
            }}
              value={newBillPayment.sku_code}
              onChange={(value) => setNewBillPayment({ ...newBillPayment, sku_code: value })}
              placeholder="Select SKU Code"
              style={{ width: "100%" }}
            >
              {products.map((product) => (
                <Select.Option key={product.id} value={product.id}>
                  {product.name}
                </Select.Option>
              ))}
            </Select>
          );
        }
        if (editingBillPayment && editingBillPayment.id === record.id) {
          return (
            <Select
            showSearch
            filterOption={(input, option) => {
              if (!option) return false;
              const optionText = option.children as unknown as string;
              return optionText.toLowerCase().includes(input.toLowerCase());
            }}
              value={editingBillPayment.sku_code.id} // Show SKU Code ID
              onChange={(value) =>
                setEditingBillPayment({
                  ...editingBillPayment,
                  sku_code: products.find((product) => product.id === value) || editingBillPayment.sku_code,
                })
              }
              placeholder="Select SKU Code"
              style={{ width: "100%" }}
            >
              {products.map((product) => (
                <Select.Option key={product.id} value={product.id}>
                  {product.name}
                </Select.Option>
              ))}
            </Select>
          );
        }
        return sku_code.name; // Display SKU Code name when not editing
      },
    },
    {
      title: () => (
        <span>
          <img src={classification} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Bill Type
        </span>
      ),
      dataIndex: "bill_type",
      key: "bill_type",
      sorter: (a: any, b: any) => a.bill_type.localeCompare(b.bill_type),
      filters: [
        { text: 'Material', value: 'Material' },
        { text: 'Utility', value: 'Utility' },
        { text: 'Service', value: 'Service' },
        { text: 'Other', value: 'Other' },
      ],
      onFilter: (value: string, record: any) => record.bill_type === value,
      render: (bill_type: string, record: any) => {
        if (record.id === -1) {
          return (
            <Select
              value={newBillPayment.bill_type}
              onChange={(value) => setNewBillPayment({ ...newBillPayment, bill_type: value })}
              placeholder="Select Bill Type"
              style={{ width: "100%" }}
            >
              {['Material', 'Utility', 'Service', 'Other'].map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          );
        }
        if (editingBillPayment && editingBillPayment.id === record.id) {
          return (
            <Select
              value={editingBillPayment.bill_type}
              onChange={(value) => setEditingBillPayment({ ...editingBillPayment, bill_type: value })}
              placeholder="Select Bill Type"
              style={{ width: "100%" }}
            >
              {['Material', 'Utility', 'Service', 'Other'].map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          );
        }
        return bill_type; // Display Bill Type when not editing
      },
    },
    {
      title: () => (
        <span>
          <img src={amount} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Amount
        </span>
      ),
      dataIndex: "amount",
      key: "amount",
      sorter: (a: any, b: any) => a.amount - b.amount,
      render: (amount: number, record: any) => {
        if (record.id === -1) {
          return (
            <Input
              value={newBillPayment.amount}
              onChange={(e) => setNewBillPayment({ ...newBillPayment, amount: e.target.value })}
              placeholder="Enter amount"
              type="number"
            />
          );
        }
        if (editingBillPayment && editingBillPayment.id === record.id) {
          return (
            <Input
              value={editingBillPayment.amount}
              onChange={(e) => setEditingBillPayment({ ...editingBillPayment, amount: e.target.value })}
              placeholder="Enter amount"
              type="number"
            />
          );
        }
        return amount; // Display Amount when not editing
      },
    },
    {
      title: () => (
        <span>
          <img src={date} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Bill Date
        </span>
      ),
      dataIndex: "payment_date",
      key: "payment_date",
      sorter: (a: any, b: any) => {
        if (!a.payment_date || !b.payment_date) return 0;
        return dayjs(a.payment_date).unix() - dayjs(b.payment_date).unix();
      },
      render: (payment_date: string, record: any) => {
        // When adding a new bill payment (new record)
        if (record.id === -1) {
          return (
            <DatePicker
              value={newBillPayment.payment_date ? dayjs(newBillPayment.payment_date) : null}
              onChange={(date, dateString) => setNewBillPayment({ ...newBillPayment, payment_date: dateString })}
              style={{ width: "100%" }}
              placeholder="Select payment date"
            />
          );
        }
    
        // When editing an existing bill payment
        if (editingBillPayment && editingBillPayment.id === record.id) {
          return (
            <DatePicker
              value={editingBillPayment.payment_date ? dayjs(editingBillPayment.payment_date) : null}
              onChange={(date, dateString) => setEditingBillPayment({ ...editingBillPayment, payment_date: dateString })}
              style={{ width: "100%" }}
              placeholder="Select payment date"
            />
          );
        }
    
        // When viewing the payment date without editing
        return payment_date ? dayjs(payment_date).format("MM-DD-YYYY") : ''; // Format as MM-DD-YYYY
      },
    },
    {
      title: "File",
      dataIndex: "file",
      key: "file",
      render: (file: any, record: any) => {
        if (record.id === -1) {
          return (
            <label className="modern-upload-btn">
              Choose File
              <input type="file" onChange={handleFileChange} />
            </label>
          );
        }
    
        if (editingBillPayment && editingBillPayment.id === record.id) {
          return (
            <label className="modern-upload-btn">
              Choose File
              <input
                type="file"
                onChange={(event) => {
                  if (event.target.files && event.target.files[0]) {
                    setEditingBillPayment({
                      ...editingBillPayment,
                      file: event.target.files[0],
                    });
                  }
                }}
              />
            </label>
          );
        }
    
        return file ? (
          <a href={file} target="_blank" rel="noopener noreferrer">
            <img src={pdf} alt="PDF" style={{ width: 24, height: 24 }} />
          </a>
        ) : "No File";
      },
    },
    {
      title: () => (
        <span>
          <img src={action} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Actions
        </span>
      ),
      key: "actions",
      render: (text: string, record: any) => {
        if (record.id === -1) {
          return (
            <span>
              <a onClick={handleSaveNewBillPayment}>
                <img src={save} alt="Save" style={{ width: "20px", height: "20px", marginRight: "8px" }} />
              </a>
              <span> | </span>
              <a onClick={() => setIsAdding(false)}>
                <img src={close1} alt="Cancel" style={{ width: "20px", height: "20px", marginLeft: "8px" }} />
              </a>
            </span>
          );
        }
  
        if (editingBillPayment && editingBillPayment.id === record.id) {
          return (
            <span>
              <a onClick={() => handleSaveEdit(editingBillPayment)}>
                <img src={save} alt="Save" style={{ width: "20px", height: "20px", marginRight: "8px" }} />
              </a>
              <span> | </span>
              <a onClick={() => setEditingBillPayment(null)}>
                <img src={close1} alt="Cancel" style={{ width: "20px", height: "20px", marginLeft: "8px" }} />
              </a>
            </span>
          );
        }
  
        return (
          <span>
            <a onClick={() => handleEdit(record)}>
              <img src={edit1} alt="Edit" style={{ width: "20px", height: "20px", marginRight: "8px" }} />
            </a>
            <span> | </span>
            <a onClick={() => handleDelete(record.id)}>
              <img src={delete1} alt="Delete" style={{ width: "20px", height: "20px", marginLeft: "8px" }} />
            </a>
          </span>
        );
      },
    },
  ];


  const dataSource = isAdding
    ? [
        {
          id: -1,
          key: -1,
          supplier: { 
            id: newBillPayment.supplier || null, 
            name: newBillPayment.supplier ? suppliers.find(s => s.id === newBillPayment.supplier)?.name || "" : "" 
          },
          sku_code: { 
            id: newBillPayment.sku_code || null, 
            name: newBillPayment.sku_code ? products.find(p => p.id === newBillPayment.sku_code)?.name || "" : "" 
          },
          bill_type: newBillPayment.bill_type,
          description: newBillPayment.description,
          amount: newBillPayment.amount,
          payment_date: newBillPayment.payment_date,
          file: newBillPayment.file
        },
        ...billPayments.map(item => ({ ...item, key: item.id }))
      ]
    : billPayments.map(item => ({ ...item, key: item.id }));

  return (
    <ContentLayoutnew
      pageTitle="Bill Payments"
      buttontitle="Add Payments"
      setModalState={() => {}}
      dataSource={dataSource as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
      onAddRow={handleAddRow}
      onDeleteSelected={handleDeleteSelected}
    />
  );
};

export default BillPayments;