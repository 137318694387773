import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification, Input, Select } from "antd";
import { AuthTokenType } from "../utils/types";
import { getAuthToken, getbudgets } from "../utils/functions";
import { useGetSupplier, useGetCategory, useGetbudgets } from "../utils/hooks";
import axios from "axios";
import ContentLayoutnew from "../components/ContentLayoutnew";
import { BudgetUrl } from "../utils/network";
import dayjs from "dayjs";
import delete1 from '../assets/delete.png'; // Import the logo image
import edit1 from '../assets/edit.png'; // Import the logo image
import action from '../assets/action.png'; // Import the logo image
import view from '../assets/view.png'; // Import the logo image
import liquor from '../assets/liquor.png'; // Import the logo image
import wastedby from '../assets/wastedby.png'; // Import the logo image
import overflow from '../assets/overflow.png'; // Import the logo image
import reason from '../assets/reason.png'; // Import the logo image
import save from '../assets/save.png'; // Import the logo image
import close1 from '../assets/close.png'; // Import the logo image

import user from '../assets/users.png'; // Import the logo image
import amount from '../assets/finance.png'; // Import the logo image
import date from '../assets/date.png'; // Import the logo image
import level1 from '../assets/type.png'; // Import the logo image
import frequency from '../assets/frequency.png'; // Import the logo image
import entity from '../assets/entity.png'; // Import the logo image




const Budgets: FC = () => {
  const [budgets, setBudgets] = useState<any[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [newBudget, setNewBudget] = useState<any>({
    id: -1,
    level: "",
    entity: "",
    budget: "",
    amount: "",
  });
  const [editingBudget, setEditingBudget] = useState<any | null>(null);
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);

  useGetSupplier(setSuppliers, setFetching);
  useGetCategory(setCategories, setFetching);
  useGetbudgets(setBudgets, setFetching);

  const handleDelete = (recordId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this Budget?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          await axios.delete(`${BudgetUrl}/${recordId}`, headers);
          getbudgets(setBudgets, setFetching);
          notification.success({ message: "Budget deleted successfully!" });
        } catch (error) {
          notification.error({ message: "Failed to delete Budget!" });
        }
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const handleDeleteSelected = async (ids: number[]) => {
    try {
      const headers = getAuthToken() as AuthTokenType;
      await Promise.all(ids.map((id) => axios.delete(`${BudgetUrl}/${id}`, headers)));
      getbudgets(setBudgets, setFetching);
      notification.success({ message: "Selected Budgets deleted successfully!" });
    } catch (error) {
      notification.error({ message: "Failed to delete selected Budgets!" });
    }
  };

  const handleAddRow = () => {
    setIsAdding(true);
    setNewBudget({
      id: -1,
      level: "",
      entity: "",
      term: "",
      amount: "",
    });
  };

  const handleSaveNewBudget = async () => {
    try {
      const headers = getAuthToken() as AuthTokenType;
      const response = await axios.post(BudgetUrl, newBudget, headers);
      setBudgets((prev) => [...prev, response.data]);
      setNewBudget({
        id: -1,
        level: "",
        entity: "",
        term: "",
        amount: "",
      });
      setIsAdding(false);
      notification.success({ message: "Budget added successfully!" });
    } catch (error) {
      notification.error({ message: "Failed to add Budget!" });
    }
  };

  const handleEdit = (record: any) => {
    setEditingBudget(record);
  };

  const handleSaveEdit = async (budget: any) => {
    try {
      const headers = getAuthToken() as AuthTokenType;
      await axios.put(`${BudgetUrl}/${budget.id}`, budget, headers);
      getbudgets(setBudgets, setFetching);
      setEditingBudget(null);
      notification.success({ message: "Budget updated successfully!" });
    } catch (error) {
      notification.error({ message: "Failed to update Budget!" });
    }
  };

  const handleLevelChange = (value: string) => {
    setNewBudget((prev: any) => ({
      ...prev,
      level: value,
      entity: "",
    }));
  };

  const columns = [

    {
      title: () => (
        <span>
          <img src={level1} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Level
        </span>
      ),
      dataIndex: "level",
      key: "level",
      render: (text: string, record: any) => {
        if (isAdding && record.id === -1) {
          return (
            <Select
              value={newBudget.level}
              onChange={handleLevelChange}
              style={{ width: "100%" }}
            >
              <Select.Option value="Vendor">Vendor</Select.Option>
              <Select.Option value="Category">Category</Select.Option>
            </Select>
          );
        }
        if (editingBudget && editingBudget.id === record.id) {
          return (
            <Select
              value={editingBudget.level}
              onChange={(value) =>
                setEditingBudget({ ...editingBudget, level: value })
              }
              style={{ width: "100%" }}
            >
              <Select.Option value="Vendor">Vendor</Select.Option>
              <Select.Option value="Category">Category</Select.Option>
            </Select>
          );
        }
        return text;
      },
    },
    {
      title: () => (
        <span>
          <img src={entity} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Entity
        </span>
      ),
      dataIndex: "entity",
      key: "entity",
      render: (text: string, record: any) => {
        if (isAdding && record.id === -1) {
          return (
            <Select
              value={newBudget.entity}
              onChange={(value) => setNewBudget({ ...newBudget, entity: value })}
              style={{ width: "100%" }}
            >
              {newBudget.level === "Vendor" &&
                suppliers.map((supplier) => (
                  <Select.Option key={supplier.id} value={supplier.name}>
                    {supplier.name}
                  </Select.Option>
                ))}
              {newBudget.level === "Category" &&
                categories.map((category) => (
                  <Select.Option key={category.id} value={category.name}>
                    {category.name}
                  </Select.Option>
                ))}
            </Select>
          );
        }
        if (editingBudget && editingBudget.id === record.id) {
          return (
            <Select
              value={editingBudget.entity}
              onChange={(value) =>
                setEditingBudget({ ...editingBudget, entity: value })
              }
              style={{ width: "100%" }}
            >
              {editingBudget.level === "Vendor" &&
                suppliers.map((supplier) => (
                  <Select.Option key={supplier.id} value={supplier.name}>
                    {supplier.name}
                  </Select.Option>
                ))}
              {editingBudget.level === "Category" &&
                categories.map((category) => (
                  <Select.Option key={category.id} value={category.name}>
                    {category.name}
                  </Select.Option>
                ))}
            </Select>
          );
        }
        return text;
      },
    },
    {
      title: () => (
        <span>
          <img src={amount} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Amount
        </span>
      ),
      dataIndex: "amount",
      key: "amount",
      render: (text: string, record: any) => {
        if (isAdding && record.id === -1) {
          return (
            <Input
              value={newBudget.amount}
              onChange={(e) =>
                setNewBudget({ ...newBudget, amount: e.target.value })
              }
              style={{ width: "100%" }}
            />
          );
        }
        if (editingBudget && editingBudget.id === record.id) {
          return (
            <Input
              value={editingBudget.amount}
              onChange={(e) =>
                setEditingBudget({ ...editingBudget, amount: e.target.value })
              }
            />
          );
        }
        return text;
      },
    },
    {
      title: () => (
        <span>
          <img src={frequency} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Term
        </span>
      ),
      dataIndex: "term",
      key: "term",
      render: (text: string, record: any) => {
        const termOptions = [
          "Weekly", "Monthly", "Quarterly", "Half Yearly", "Annual"
        ];
    
        if (isAdding && record.id === -1) {
          return (
            <Select
              value={newBudget.term}
              onChange={(value) => setNewBudget({ ...newBudget, term: value })}
              style={{ width: "100%" }}
            >
              {termOptions.map((term) => (
                <Select.Option key={term} value={term}>
                  {term}
                </Select.Option>
              ))}
            </Select>
          );
        }
        if (editingBudget && editingBudget.id === record.id) {
          return (
            <Select
              value={editingBudget.term}
              onChange={(value) => setEditingBudget({ ...editingBudget, term: value })}
            >
              {termOptions.map((term) => (
                <Select.Option key={term} value={term}>
                  {term}
                </Select.Option>
              ))}
            </Select>
          );
        }
        return text;
      },
    },

     {
      title: () => (
        <span>
          <img src={action} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Actions
        </span>
      ),
      key: "actions",
      render: (text: string, record: any) => {
        if (isAdding && record.id === -1) {
          return (
            <span>
              <a onClick={handleSaveNewBudget}>
                <img src={save} alt="Save" style={{ width: "20px", height: "20px", marginRight: "8px" }} />
              </a>
              <span> | </span>
              <a
                onClick={() => {
                  setIsAdding(false);
                  setNewBudget({ id: -1, level: "", entity: "", term: "", amount: "" });
                }}
              >
                <img src={close1} alt="Cancel" style={{ width: "20px", height: "20px", marginLeft: "8px" }} />
              </a>
            </span>
          );
        }
  
        if (editingBudget && editingBudget.id === record.id) {
          return (
            <span>
              <a onClick={() => handleSaveEdit(editingBudget)}>
                <img src={save} alt="Save" style={{ width: "20px", height: "20px", marginRight: "8px" }} />
              </a>
              <span> | </span>
              <a onClick={() => setEditingBudget(null)}>
                <img src={close1} alt="Cancel" style={{ width: "20px", height: "20px", marginLeft: "8px" }} />
              </a>
            </span>
          );
        }
  
        return (
          <span>
            <a onClick={() => handleEdit(record)}>
              <img src={edit1} alt="Edit" style={{ width: "20px", height: "20px", marginRight: "8px" }} />
            </a>
            <span> | </span>
            <a onClick={() => handleDelete(record.id)}>
              <img src={delete1} alt="Delete" style={{ width: "20px", height: "20px", marginLeft: "8px" }} />
            </a>
          </span>
        );
      },
    },
  ];

  const dataSource = isAdding
    ? [...budgets, { id: -1, level: newBudget.level }]
    : budgets;

  return (
    <ContentLayoutnew
      pageTitle="Budgets"
      buttontitle="Add Budget"
      setModalState={handleAddRow}
      dataSource={dataSource as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
      onAddRow={handleAddRow}
      onDeleteSelected={handleDeleteSelected}
    />
  );
};

export default Budgets;