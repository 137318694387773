import { useState, useEffect } from "react";
import { Spin, Drawer, Table, Checkbox, DatePicker, Button } from "antd";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
} from "recharts";
import { getpurchasereport } from "../../utils/functions";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const Purchasereport = () => {
  const [budgetData, setBudgetData] = useState<any[]>([]);
  const [monthlyData, setMonthlyData] = useState<any[]>([]);
  const [fetching, setFetching] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedBillTypes, setSelectedBillTypes] = useState<Record<string, boolean>>({});
  const [cursorStyle, setCursorStyle] = useState("default");

  // Default date range (last 30 days)
  const defaultStartDate = dayjs().subtract(90, "days").format("YYYY-MM-DD");
  const defaultEndDate = dayjs().format("YYYY-MM-DD");
  
  const [dateRange, setDateRange] = useState<[string, string]>([defaultStartDate, defaultEndDate]);
  const [tempDateRange, setTempDateRange] = useState<[string, string]>([defaultStartDate, defaultEndDate]);

  // Fetch purchase report (runs on mount and when "Apply" is clicked)
  const fetchReport = (range: [string, string]) => {
    setFetching(true);
    getpurchasereport((data) => {
      setBudgetData(Array.isArray(data) ? data : []);
      setSelectedBillTypes(
        data.reduce((acc: any, item: any) => {
          acc[item.name] = true;
          return acc;
        }, {})
      );
      setFetching(false);
    }, setFetching, range);
  };

  // Fetch data on first render with default date range
  useEffect(() => {
    fetchReport(dateRange);
  }, []);

  const handleDateChange = (dates: any, dateStrings: [string, string]) => {
    setTempDateRange(dates ? dateStrings : dateRange);
  };

  const applyDateFilter = () => {
    setDateRange(tempDateRange);
    fetchReport(tempDateRange); // Fetch data when "Apply" is clicked
  };

  const handlePieClick = (data: any) => {
    if (data) {
      setSelectedCategory(data.name);
      setMonthlyData(Array.isArray(data.monthly_breakdown) ? data.monthly_breakdown : []);
      setDrawerVisible(true);
    }
  };

  const totalSpend = budgetData.reduce((sum, item) => sum + item.spend, 0);
  const topSpendBillTypes = [...budgetData].sort((a, b) => b.spend - a.spend).slice(0, 5);
  const tableData = [...topSpendBillTypes, { name: "Total", spend: totalSpend }];

  const columns = [
    {
      title: "Bill Type",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (text: string) => (text === "Total" ? <strong>{text}</strong> : text),
    },
    {
      title: "Total Spend",
      dataIndex: "spend",
      key: "spend",
      align: "center",
      render: (value: any, record: any) =>
        record.name === "Total" ? <strong>${value.toFixed(2)}</strong> : `$${value.toFixed(2)}`,
    },
  ];

  return (
    <div className="card">
      <div className="borderline">
        <div className="cardHeader">
          <h2 className="headContent">Purchases</h2>
        </div>

        <div className="leftcontent" style={{ display: "flex", gap: "10px", alignItems: "right", marginBottom: "10px" }}>
          <RangePicker
            onChange={handleDateChange}
            format="YYYY-MM-DD"
            value={tempDateRange ? [dayjs(tempDateRange[0]), dayjs(tempDateRange[1])] : undefined}
            allowClear
            className="customInput"
          />
          <Button type="primary" className="bbutton" onClick={applyDateFilter}>
            Apply
          </Button>
        </div>

        <div className="piechartContainer" style={{ display: "flex", gap: "10px" }}>
          {fetching ? (
            <Spin size="small" />
          ) : (
            <>
              <Table
                className="custom-table"
                dataSource={tableData}
                columns={columns as any}
                pagination={false}
                size="small"
                rowKey="name"
                style={{ width: "35%" }}
              />
              <ResponsiveContainer width="50%" height={350}>
                <PieChart>
                  <text x="50%" y="30" textAnchor="middle" dominantBaseline="middle" fontSize="16">
                    Purchases by Type
                  </text>
                  <Tooltip formatter={(value: any) => `$${value.toFixed(2)}`} />
                  <Legend
                    verticalAlign="middle"
                    layout="vertical"
                    align="right"
                    content={() => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          border: "1px solid #ddd",
                          padding: "5px",
                          borderRadius: "5px",
                        }}
                      >
                        {budgetData.map((entry) => (
                          <Checkbox
                            key={entry.name}
                            checked={selectedBillTypes[entry.name]}
                            onChange={() =>
                              setSelectedBillTypes((prev) => ({
                                ...prev,
                                [entry.name]: !prev[entry.name],
                              }))
                            }
                          >
                            {`${entry.name} (${((entry.spend / totalSpend) * 100).toFixed(1)}%)`}
                          </Checkbox>
                        ))}
                      </div>
                    )}
                  />
                  <Pie
                    data={budgetData.filter((item) => selectedBillTypes[item.name] && item.name !== "Total")}
                    dataKey="spend"
                    onMouseOver={() => setCursorStyle("pointer")}
                    onMouseOut={() => setCursorStyle("default")}
                    style={{ cursor: cursorStyle }}
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    fill="#8884d8"
                    onClick={(data) => handlePieClick(data)}
                  >
                    {budgetData
                      .filter((item) => item.name !== "Total")
                      .map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </>
          )}
        </div>

        <Drawer
          title={`Monthly Breakdown for ${selectedCategory}`}
          placement="right"
          closable
          onClose={() => setDrawerVisible(false)}
          open={drawerVisible}
          width={500}
        >
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={monthlyData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" tick={{ fontSize: 12 }} label={{ value: "Month", position: "insideBottom", offset: -5 }} />
              <YAxis tick={{ fontSize: 12 }} label={{ value: "USD", angle: -90, position: "insideLeft" }} />
              <Tooltip />
              <Legend align="center" wrapperStyle={{ bottom: -2 }} />
              <Line type="monotone" dataKey="spend" stroke="#82ca9d" name="Spend" />
            </LineChart>
          </ResponsiveContainer>
        </Drawer>
      </div>
    </div>
  );
};

export default Purchasereport;
