import { FC, useEffect, useState } from "react";
import { Button, Table, notification, Input } from "antd";
import { AuthTokenType, DataProps } from "../utils/types";
import { saveAs } from 'file-saver';
import axios, { AxiosResponse } from "axios";
import { getAuthToken } from "../utils/functions";
import { masterdatauploadURL } from "../utils/network";

interface ContentLayoutProps {
  children: React.ReactNode;
  pageTitle: string;
  buttontitle: string;
  setModalState: (val: boolean) => void;
  dataSource: DataProps[];
  columns: DataProps[];
  fetching: boolean;
  includeFilters: boolean;
  reportButton?: () => void; // Optional report button function
}

const ContentLayout: FC<ContentLayoutProps> = ({
  children,
  pageTitle,
  buttontitle,
  setModalState,
  dataSource,
  columns,
  fetching,
  includeFilters,
  reportButton, // Optional report button function
}) => {
  const [filteredColumns, setFilteredColumns] = useState(columns);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (dataSource && columns && includeFilters) {
      const newColumns = columns.map((column) => {
        const uniqueValues = Array.from(
          new Set(dataSource.map((data) => data[column.dataIndex as string]))
        );
        const filters = uniqueValues.map((value) => ({
          text: value,
          value,
        }));
        return {
          ...column,
          filters,
          onFilter: (value: any, record: any) =>
            record[column.dataIndex as string] === value,
        };
      });
      setFilteredColumns(newColumns as any);
    }
  }, [dataSource, columns, includeFilters]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = dataSource.filter((record) =>
    Object.values(record).some((value) =>
      value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleExportToCSV = () => {
    // Convert data to CSV format
    const csvContent = columns.map(column => column.title).join('\t') + '\n' +
      filteredData.map(row => columns.map(column => row[column.dataIndex as string]).join('\t')).join('\n');
  
    // Create a Blob object containing the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  
    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);
  
    // Create a link element
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "data.csv");
  
    // Append the link to the document body and trigger the click event
    document.body.appendChild(link);
    link.click();
  
    // Clean up by revoking the temporary URL
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <div className="card">
        <div className="borderline">
          <div className="cardHeader">
            <h2 className="headContent">{pageTitle}</h2>
          </div>

          <div className="rightContent">
            <Input
              className="customInput"
              placeholder="Search..."
              onChange={handleSearch}
              style={{ marginRight: '10px', width: "200px", height: "40px" }}
            />
            <Button className="bbutton" onClick={() => setModalState(true)}>
              {buttontitle}
            </Button>
            <Button className="bbutton" onClick={handleExportToCSV}>
              Download
            </Button>
            {reportButton && ( // Conditionally render the Report button if reportButton is passed
              <Button className="bbutton" onClick={reportButton}>
                Report
              </Button>
            )}
          </div>
          <br />
          <Table
            className="custom-table"
            dataSource={filteredData}
            columns={filteredColumns}
            pagination={{ pageSize: 20 }}
            size="small"
            loading={fetching}
          />
        </div>
      </div>
      {children}
    </>
  );
};

export default ContentLayout;