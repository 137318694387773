import { BrowserRouter, Route, Routes  } from 'react-router-dom';
import Login from "./pages/Login"
import CheckUser from "./pages/CheckUser"
import Home from "./pages/Home"
import AuthRoute from './components/AuthRoute';
import User from './pages/User';
import UpdateUserPassword from './pages/UpdateUserPassword';
import Categories from './pages/Categories';
import Unitofmeasure from './pages/Unitofmeasure';
import Locations from './pages/Locations';
import Products from './pages/Products';
import OnHandInventory from './pages/OnHandInventory';
import ResetPassword from './pages/ResetPassword';
import ForgetPassword from './pages/ForgotPassword';
import Suppliers from './pages/Suppliers';
import PurchaseOrder from './pages/AddPurchase';
import PurchaseOrderReview from './pages/PurchaseOrderReview';
import InventoryCount from './pages/InventoryCount';
import Budgets from './pages/Budgets';
import WasteLog from './pages/Wastelog';
import Wastesummary from './components/WasteReport';
import PriceAlerts from './pages/PriceAlerts';
import InventoryCheckReminders from './pages/InventoryCheckReminder';
import BillPayments from './pages/BillPayments';
import Equipment from './pages/Equipment';
import Recipe from './pages/Recipe';
import BudgetComparison from './components/Dashboard/BudgetComparison';

function Router() {
  return (
  <BrowserRouter>
      <Routes>
      
      <Route path="/Login"  element={<Login/>}/>
      <Route path="/check-user"  element={<CheckUser/>}/>
      <Route path="/create-password"  element={<UpdateUserPassword/>}/>
      <Route path="/forgotpassword" element={<ForgetPassword/>}/>
      <Route path="/resetpassword" element={<ResetPassword/>}/>


      <Route path="/" element={<AuthRoute><Home/></AuthRoute>}/>
      <Route path="/users" element={<AuthRoute><User/></AuthRoute>}/>
      <Route path="/category" element={<AuthRoute><Categories/></AuthRoute>}/>
      <Route path="/uom" element={<AuthRoute><Unitofmeasure/></AuthRoute>}/>
      <Route path="/location" element={<AuthRoute><Locations/></AuthRoute>}/>
      <Route path="/product" element={<AuthRoute><Products/></AuthRoute>}/>
      <Route path="/supplier" element={<AuthRoute><Suppliers/></AuthRoute>}/>
      <Route path="/onhand" element={<AuthRoute><OnHandInventory/></AuthRoute>}/>
      <Route path="/purchase" element={<AuthRoute><PurchaseOrder/></AuthRoute>}/>
      <Route path="/purchasereview" element={<AuthRoute><PurchaseOrderReview/></AuthRoute>}/>
      <Route path="/inventorycount" element={<AuthRoute><InventoryCount/></AuthRoute>}/>
      <Route path="/budgets" element={<AuthRoute><Budgets/></AuthRoute>}/>
      <Route path="/wastelog" element={<AuthRoute><WasteLog/></AuthRoute>}/>
      <Route path="/wastesummary" element={<AuthRoute><Wastesummary /></AuthRoute>} />
      <Route path="/pricealert" element={<AuthRoute><PriceAlerts /></AuthRoute>} />
      <Route path="/inventorycheck" element={<AuthRoute><InventoryCheckReminders /></AuthRoute>} />

     <Route path="/billpayment" element={<AuthRoute><BillPayments /></AuthRoute>} />
     <Route path="/equipment" element={<AuthRoute><Equipment /></AuthRoute>} />
     <Route path="/recipe" element={<AuthRoute><Recipe /></AuthRoute>} />
    
   

      </Routes>
  </BrowserRouter>
  )
}
export default Router