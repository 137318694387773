import { useContext,useEffect } from "react"
import { ActionTypes,AuthProps,UserType, purchaseProps} from "./types"
import { authHandler,getCategory,getLocation, getOnhandInventory,getOnhandInventoryupdated,getProduct, getProductwithinventory, getSankey,getSupplier,getUnitofmeasure, getUsers, getbillpayment, getbudgetcomparison, getbudgets, getequipment, getinventorycheck, getpricealerts, getproductinventory, getproductprice, getpurchase, getrecipe, getwastelog, getwastesummary} from "./functions"
import { store } from "./store"




export const useAuth =async({errorCallBack,successCallBack}:AuthProps)=>{
    const{dispatch}=useContext(store)
    
    useEffect(()=>{
        const checkUser=async () => {
            const user: UserType|null=await authHandler()
            if(!user){
                if(errorCallBack){
                    errorCallBack()
                }
                return
            }
            if(successCallBack){
                dispatch({type:ActionTypes.UPDATE_USER_INFO,payload:user})
                successCallBack()
            }
            
        }
        checkUser()
    },[])
}


export const useGetUsers = (setUsers: (data: any) => void, setFetching: (val: boolean) => void) => {
  useEffect(() => {
    setFetching(true);
    getUsers(setUsers, setFetching);
  }, [setUsers, setFetching]);
};


export const useGetCategory = (setCategory: (data: any) => void, setFetching: (val: boolean) => void) => {
    useEffect(() => {
      setFetching(true);
      getCategory(setCategory, setFetching);
    }, [setCategory, setFetching]);
  };
  
  export const useGetUnitofmeasure = (
    setUnitofmeasure: (data: any) => void,
    setFetching: (val: boolean) => void
  ) => {
    useEffect(() => {
      setFetching(true);
      getUnitofmeasure(setUnitofmeasure, setFetching);
    }, [setUnitofmeasure, setFetching]);
  };
  
  
  export const useGetLocation = (setLocation: (data: any) => void, setFetching: (val: boolean) => void) => {
    useEffect(() => {
      setFetching(true);
      getLocation(setLocation, setFetching);
    }, [setLocation, setFetching]);
  };
  

  export const useGetOnhandIventory = (setOnhandInventory: (data: any) => void, setFetching: (val: boolean) => void) => {
    useEffect(() => {
      setFetching(true);
      getOnhandInventory(setOnhandInventory, setFetching);
    }, [setOnhandInventory, setFetching]);
  };



  export const useGetOnhandIventoryupdated = (setOnhandInventory: (data: any) => void, setFetching: (val: boolean) => void) => {
    useEffect(() => {
      setFetching(true);
      getOnhandInventoryupdated(setOnhandInventory, setFetching);
    }, [setOnhandInventory, setFetching]);
  };

    
  export const useGetProduct = (setProduct: (data: any) => void, setFetching: (val: boolean) => void) => {
    useEffect(() => {
      setFetching(true);
      getProduct(setProduct, setFetching);
    }, [setProduct, setFetching]);
  };
  
  export const useGetProductwithinventory = (setProduct: (data: any) => void, setFetching: (val: boolean) => void) => {
    useEffect(() => {
      setFetching(true);
      getProductwithinventory(setProduct, setFetching);
    }, [setProduct, setFetching]);
  };
    
  export const useGetSankey=(setSankey:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getSankey(setSankey,setFetching)
    },[])
  
  }


  
  export const useGetproductprice=(setproductprice:(data:any[])=>void,setFetching:(val:boolean)=>void,product_id:any)=>{
    useEffect(()=>{
      getproductprice(setproductprice,setFetching,product_id)
    },[])
  
  }


  export const useGetproductinventory=(setproductinventory:(data:any[])=>void,setFetching:(val:boolean)=>void,product_id:any,location_id:any)=>{
    useEffect(()=>{
      getproductinventory(setproductinventory,setFetching,product_id,location_id)
    },[])
  
  }


  export const useGetSupplier = (setSupplier: (data: any) => void, setFetching: (val: boolean) => void) => {
    useEffect(() => {
      setFetching(true);
      getSupplier(setSupplier, setFetching);
    }, [setSupplier, setFetching]);
  };
  

  export const useGetpurchase=(setpurchase:(data:purchaseProps[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getpurchase(setpurchase,setFetching)
    },[])
  
  }
 

  export const useGetbudgets=(setbudgets:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getbudgets(setbudgets,setFetching)
    },[])
  
  }
  export const useGetwastelog=(setwastelog:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getwastelog(setwastelog,setFetching)
    },[])  
  }

  export const useGetwastesummary=(setwastesummary:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getwastesummary(setwastesummary,setFetching)
    },[])  
  }



  export const useGetPricealert=(setpricealert:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getpricealerts(setpricealert,setFetching)
    },[])
  
  }

  export const useGetinventorycheck=(setinventorycheck:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getinventorycheck(setinventorycheck,setFetching)
    },[])
  
  }


  export const useGetbillpayment=(setbillpayment:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getbillpayment(setbillpayment,setFetching)
    },[])
  
  }



  export const useGetequipment=(setequipment:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getequipment(setequipment,setFetching)
    },[])
  
  }


  export const useGetrecipe=(setrecipe:(data:any[])=>void,setFetching:(val:boolean)=>void)=>{
    useEffect(()=>{
      getrecipe(setrecipe,setFetching)
    },[])
  
  }


  export const useBudgetcomparison=(setbudgetcomparison:(data:any[])=>void,setFetching:(val:boolean)=>void,level:any, term:any, entity_name:any)=>{
    useEffect(()=>{
      getbudgetcomparison(setbudgetcomparison,setFetching,level, term, entity_name)
    },[])
  
  }

