import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification, Tag, Input } from "antd";
import { AuthTokenType, Recipeprops} from "../utils/types";
import { getAuthToken, getrecipe } from "../utils/functions";
import axios from "axios";
import ContentLayout from "../components/ContentLayout";
import { useGetrecipe } from "../utils/hooks";
import { recipeURL } from "../utils/network";
import AddRecipeForm from "../components/AddRecipeForm";
import RecipeViewDrawer from "../components/RecipeViewer";
import delete1 from '../assets/delete.png'; // Import the logo image
import edit1 from '../assets/edit.png'; // Import the logo image
import action from '../assets/action.png'; // Import the logo image
import view from '../assets/view.png'; // Import the logo image

import cost from '../assets/cost.png'; // Import the logo image
import recipebook from '../assets/recipebook.png'; // Import the logo image
import prep from '../assets/preparation.png'; // Import the logo image
import cooking from '../assets/cooking.png'; // Import the logo image


const Recipe: FC = () => {
  const [recipes, setRecipes] = useState<Recipeprops[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [drawerVisible, setDrawerVisible] = useState(false); // State for Add Recipe drawer
  const [editingRecipe, setEditingRecipe] = useState<Recipeprops | null>(null); // Editing state

  const [viewDrawerVisible, setViewDrawerVisible] = useState(false); // New state for viewer
  const [selectedRecipe, setSelectedRecipe] = useState<Recipeprops | null>(null); // Selected recipe for viewing


  const handleView = (recipe: Recipeprops) => {
    setSelectedRecipe(recipe);
    setViewDrawerVisible(true);
  };
  useGetrecipe(setRecipes, setFetching); // Fetch recipes using custom hook


  const handleDelete = (recipeId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this recipe?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;
          await axios.delete(`${recipeURL}${recipeId}/`, headers);
          getrecipe(setRecipes, setFetching); // Refetch after delete
          notification.success({ message: "Recipe deleted successfully!" });
        } catch (error) {
          notification.error({ message: "Failed to delete recipe!" });
        }
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const handleEdit = (recipe: Recipeprops) => {
    setEditingRecipe(recipe); // Set editing state for updating
    setDrawerVisible(true); // Open the drawer
  };

  const onCloseWithoutEditing = () => {
    setEditingRecipe(null); // Reset editingCategory when form is closed without editing

  };


  const columns = [

    {
      title: () => (
        <span>
          <img src={recipebook} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Name
        </span>
      ),
      dataIndex: "name",
      key: "name",
    },
    {
      title: () => (
        <span>
          <img src={prep} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Prep Time (minutes)
        </span>
      ),

      dataIndex: "preparation_time",
      key: "preparation_time",
    },
    {
      title: () => (
        <span>
          <img src={cooking} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Cooking Time (minutes)
        </span>
      ),
      dataIndex: "cooking_time",
      key: "cooking_time",
    },
    {
      title: () => (
        <span>
          <img src={cost} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Cost (USD)
        </span>
      ),
      dataIndex: "cost",
      key: "cost",
    },
    {
      title: () => (
        <span>
          <img src={action} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Action
        </span>
      ),
      key: "actions",
      render: (_: any, record: Recipeprops) => (
        <span>
             <a onClick={() => handleView(record)}>  <img
            src={view}
            alt="delete"
            style={{ width: '20px', height: '20px', marginRight: '8px' }}
          /></a>
             <span> | </span>
          <a onClick={() => handleEdit(record)}> <img
            src={edit1}
            alt="delete"
            style={{ width: '20px', height: '20px', marginRight: '8px' }}
          /></a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}> <img
            src={delete1}
            alt="delete"
            style={{ width: '20px', height: '20px', marginRight: '8px' }}
          /></a>
        </span>
      ),
    },
  ];

  return (
    <ContentLayout
      pageTitle="Recipes"
      buttontitle="Add Recipe"
      setModalState={setDrawerVisible}
      dataSource={recipes as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
    >
      <AddRecipeForm
        isVisible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        onSuccessCallBack={() => {
          getrecipe(setRecipes, setFetching); // Refetch after adding or editing
          setDrawerVisible(false); // Close the drawer
        }}
        editingRecipe={editingRecipe}
        onCloseWithoutEditing={onCloseWithoutEditing}

      />
        {selectedRecipe && (
        <RecipeViewDrawer
          recipe={selectedRecipe}
          isVisible={viewDrawerVisible}
          onClose={() => setViewDrawerVisible(false)}
        />
      )}
    </ContentLayout>
  );
};

export default Recipe;
