import React, { FC, useState, useEffect } from "react";
import { Drawer, notification, Form, Input, Button, Switch, Select } from "antd";
import { AuthTokenType, DataProps, AddCategoryFormProps, category } from "../utils/types";
import { getAuthToken, getCategory } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { categoriesUrl } from "../utils/network";
import { useGetCategory } from "../utils/hooks";
import { FormInstance } from 'antd/lib/form/Form';

// Define an interface for the form values
interface FormValues {
  name: string;
  is_parent: boolean;
  parent_id: string;
}

const AddCategoryForm: FC<AddCategoryFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  onCloseWithoutEditing, // new prop
  editingCategory,
}) => {
  const [form] = Form.useForm<FormValues>(); // Specify the generic type for FormInstance
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [fetching, setFetching] = useState<boolean>(true);
  const [showParentField, setShowParentField] = useState<boolean>(true); // State to track the visibility of the Parent field

  const [categories, setCategories] = useState<category[]>([]);
  useGetCategory(setCategories, setFetching);

  useEffect(() => {
    if (editingCategory) {
      form.setFieldsValue({
        ...editingCategory,
      });
    } else {
      form.resetFields();
    }
  }, [editingCategory, form]);

  // Handle changes in the "Is Parent" switch
  const handleIsParentChange = (value: boolean) => {
    setShowParentField(!value); // Show Parent field if Is Parent is false
  };

  const onSubmit = async (values: FormValues) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let response: AxiosResponse;

      if (editingCategory) {
        response = await axios.put(`${categoriesUrl}/${editingCategory.id}/`, values, headers);
      } else {
        response = await axios.post(categoriesUrl + '/', values, headers);
      }

      setLoading(false);

      if (response) {
        form.resetFields();
      
        getCategory(setCategories, setFetching);
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: "Operation Error",
      });
      setLoading(false);
    }
  };

  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };

  return (
    <Drawer
      title={editingCategory ? "Edit Category" : "Add Category"}
      visible={isVisible}
      onClose={handleFormClose}
      destroyOnClose
      width={360}
    >
      <Form layout="vertical" onFinish={onSubmit} form={form} onValuesChange={() => setHasChanges(true)}>
        <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input the category name!' }]}>
          <Input placeholder="Category Name" />
        </Form.Item>
        <Form.Item
          label={("Is Parent")}
          name="is_parent"
          valuePropName="checked"
          initialValue={false}
        >
          <Switch onChange={handleIsParentChange} />
        </Form.Item>

        {showParentField && (
          <Form.Item
            label="Parent"
            name="parent_id"
            rules={[{ required: false, message: 'Please select the category!' }]}
          >
            <Select>
              <Select.Option value="" disabled>Select a Parent</Select.Option>
              {categories.map(category => (
                <Select.Option key={category.id} value={category.id}>
                  {category.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item>
          <Button htmlType="submit" type="primary" block loading={loading}>
            {editingCategory ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddCategoryForm;