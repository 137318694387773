import React, { useEffect, useState, ChangeEvent } from "react";
import { getAuthToken } from "../utils/functions";
import {
  AddRemovePurchaseItemProps,
  AuthTokenType,
  location,
  product,
  purchaseProps,
  supplier,
} from "../utils/types";
import { useGetProduct, useGetLocation, useGetSupplier } from "../utils/hooks";
import { Button, Form, Input, Table, notification, Select, Switch, Radio } from "antd";
import axios, { AxiosResponse } from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { onhandUrl, purchaseorderUrl } from "../utils/network";
import { ForkRight } from "@mui/icons-material";

import delete1 from '../assets/delete.png'; // Import the logo image
import edit1 from '../assets/edit.png'; // Import the logo image
import action from '../assets/action.png'; // Import the logo image
import view from '../assets/view.png'; // Import the logo image
import liquor from '../assets/liquor.png'; // Import the logo image
import wastedby from '../assets/wastedby.png'; // Import the logo image
import overflow from '../assets/overflow.png'; // Import the logo image
import supplier1 from '../assets/delivery.png'; // Import the logo image
import classification from '../assets/classification.png'; // Import the logo image
import close1 from '../assets/close.png'; // Import the logo image
import save from '../assets/save.png'; // Import the logo image

import user from '../assets/users.png'; // Import the logo image
import amount from '../assets/finance.png'; // Import the logo image
import date from '../assets/date.png'; // Import the logo image




const { Option } = Select;

const formatProductProps = (
  product: product[],
  onAddItem: (productdata: product) => void,
  onChangeQty: (value: number, product_id: number) => void
) => {
  return product.map((item) => ({
    ...item,
    key: item.id,
    action: (
      <div>
        <Input
          style={{ width: "8vw" }}
          type="number"
          min={1}
          defaultValue={1}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeQty(parseInt(e.target.value), item.id as any)
          }
        />
        <Button
          style={{ width: "50px" }}
          onClick={() => onAddItem(item)}
        >
          +
        </Button>
      </div>
    ),
  }));
};

const formatPurchaseDataProps = (
  purchasedata: purchaseProps[],
  onRemoveItem: (productid: number) => void,
  onChangeQty: (value: number, product_id: number) => void
) => {
  return purchasedata.map((item) => ({
    ...item,
    key: item.id,
    action: (
      <div>
        <Input
          style={{ width: "5vw" }}
          type="number"
          min={1}
          max={item.quantity}
          defaultValue={1}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeQty(parseInt(e.target.value), item.id)
          }
        />
        <Button
          style={{ width: "50px" }}
          onClick={() => onRemoveItem(item.id)}
        >
          -
        </Button>
      </div>
    ),
  }));
};

function PurchaseOrder() {
  const [fetching, setFetching] = useState(true);
  const [product, setProduct] = useState<product[]>([]);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [purchasedata, setPurchaseData] = useState<purchaseProps[]>([]);
  const [purchaseitemqty, setPurchaseItemQty] =
    useState<AddRemovePurchaseItemProps>({});
  const [purchaseitemqdataty, setPurchaseItemDataQty] =
    useState<{ [key: number]: number }>({});

  const [fromlocation, setFromLocation] = useState<supplier[]>([]);
  const [fromlocationselected, setFromLocationSelected] = useState("");
  const [loading, setLoading] = useState(false);

  const [edd, Setedd] = useState<Date | null>(new Date());
  const [depdate, Setdepdate] = useState<Date | null>(new Date());

  const [tolocationselected,setToLocationSelected]=useState("")
  const [tolocation,setToLocation]=useState<location[]>([])

  const [deliveryOption, setDeliveryOption] = useState<"Requesting" | "Immediate" | "NextDay" | null>(null);  
  const [immediateOption, setImmediateOption] = useState<"NextDay" | "ImmediatePickup" | null>(null);

useEffect(() => {
  if (immediateOption === "NextDay") {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    Setedd(tomorrow);
  } else if (immediateOption === "ImmediatePickup") {
    const today = new Date();
    Setedd(today);
  }
}, [immediateOption]);

  const handleCostChange = (value: string, productId: number) => {
    const newCost = parseFloat(value);
    setProduct((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId ? { ...product, sku_buy_cost: newCost } : product
      )
    );
  };
  const productcolumns:any = [
    {
      title: ("Id"),
      dataIndex: "id",
      key: "id",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={("Search code")}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<ForkRight />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            {("Search")}
          </Button>
          <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
            {("Reset")}
          </Button>
        </div>
      ),
      filterIcon: (filtered: boolean) => <ForkRight style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value: string, record: product) => record.id.toString().includes(value),
    },
    {
      title: ("Name"),
      dataIndex: "name",
      key: "name",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={("Search code")}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<ForkRight />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            {("Search")}
          </Button>
          <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
            {("Reset")}
          </Button>
        </div>
      ),
      filterIcon: (filtered: boolean) => <ForkRight style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value: string, record: product) => record.id.toString().includes(value),
    },
      {
      title: ("Cost"),
      dataIndex: "costperwholeunit",
      key: "costperwholeunit",

    },
    {
      title: ("Actions"),
      dataIndex: "action",
      key: "action",
    },
  ];

  const purchaseordercolumns = [
    {
      title: ("Name"),
      dataIndex: "item",
      key: "item",
    },

    {
      title: ("Quantity"),
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: ('Amount'),
      dataIndex: 'amount',
      key: 'amount',
    },

    {
      title: ("Actions"),
      dataIndex: "action",
      key: "action",
    },
  ];

  useGetProduct(setProduct, setFetching);

  useGetSupplier(setFromLocation, setFetching);
  useGetLocation(setToLocation, setFetching);

  const additemtopurchasedata = (productdata: product) => {
    const qty = purchaseitemqty[productdata.id as any] || 1;

    let _purchasedata: purchaseProps[] = [];

    const id = purchasedata.filter(
      (id) => id.id === (productdata.id as any)
    );
    if (id.length > 0) {
      _purchasedata = purchasedata.map((id) => {
        if (id.id === (productdata.id as any)) {
          const _qty = id.quantity + qty;
          return {
            ...id,
            quantity: _qty,
            amount:(productdata.costperwholeunit*(_qty)).toFixed(2),


          };
        }
        return id;
      });
    } else {
      const temppurchasedata: purchaseProps = {
        key: productdata.id as any,
        item: productdata.name as any,
        id: productdata.id as any,
        quantity: qty,
        cost:productdata.costperwholeunit,

        amount:(productdata.costperwholeunit*qty).toFixed(2),
     
     

      };

      _purchasedata = [...purchasedata, temppurchasedata];
    }
    setPurchaseData(_purchasedata);
  };

  const removeitemfrompurchasedata = (productid: number) => {
    const qty = purchaseitemqdataty[productid] || 1;
    let _purchasedata: purchaseProps[] = [];
    const item = purchasedata.filter((item) => item.id === productid)[0];

    if (qty >= item.quantity) {
      _purchasedata = purchasedata.filter((item) => item.id !== productid);
    } else {
      _purchasedata = purchasedata.map((item) => {
        if (item.id === productid) {
          return {
            ...item,
            quantity: item.quantity - qty,
            amount:((item.cost)*(item.quantity-qty)).toFixed(2)         


          };
        }
        return item;
      });
    }
    setPurchaseData(_purchasedata);
  };

  const changepurchaseqty = (value: number, product_id: number) => {
    setPurchaseItemQty({
      ...purchaseitemqty,
      [product_id]: value,
    });
  };

  const changepurchaseremoveqty = (value: number, product_id: number) => {
    setPurchaseItemDataQty({
      ...purchaseitemqty,
      [product_id]: value,
    });
  };

  const clearPurchaseOrderData = () => {
    setPurchaseData([]);
    setPurchaseItemDataQty([]);
    form.resetFields();
  };


  const submitsto = async () => {
    if (purchasedata.length < 1) {
      notification.error({
        message: ("Add Items to Order"),
      });
    } else {
      
      const headers = getAuthToken() as AuthTokenType;

      const datatosend = {
        from_location_id: fromlocationselected,
        to_location_id: tolocationselected,
        expected_delivery_date: edd,
        actual_delivery_date: edd,
        status: "Open",
        po_number: form.getFieldValue("po_number"),
        departure_date: form.getFieldValue("departure_date"),
      shipping_company: form.getFieldValue("shipping_company"),
      material_reference: form.getFieldValue("material_reference"),
        purchaseorder_items_data: purchasedata.map((item) => ({
          item_id: item.key,
          quantity: item.quantity,
          amount:item.amount
        })),
      };

      setLoading(true);


      try {
        const response: AxiosResponse = await axios.post(
          purchaseorderUrl,
          datatosend,
          headers
        );

        notification.success({
          message: ("Operation Success"),
          description: ("Order Created Successfully"),
        });
      } catch (e) {
        notification.error({
          message: "Operation Error",
        });
        form.resetFields();
      } finally {
        form.resetFields();
        setLoading(false);
      }
    }
  };
  const [form] = Form.useForm();

  return (
    <div className="ordercreation">
      <div className="cardcart1">
        <div className="cardHeader">
          <h2 className="headContent">{("Products")}</h2>
        
        </div>
        <br/>
        <Table
          className="custom-table"
          size="small"
          dataSource={formatProductProps(
            product,
            additemtopurchasedata,
            changepurchaseqty
          )}
          columns={productcolumns}
        />
      </div>
      <br />

      <div className="cardcart2">
        <div className="cardHeader">
          <h2 className="headContent">{("Purchase Order")}</h2>
        </div>
        <br/>
        <Table
          className="custom-table"
          size="small"
          dataSource={formatPurchaseDataProps(
            purchasedata,
            removeitemfrompurchasedata,
            changepurchaseremoveqty
          )}
          columns={purchaseordercolumns}
        />
        <Form layout="vertical" form={form}>
          <br/>
          <Form.Item  name="from_location_id"
          rules={[
            {
              required: true,
              message: 'Please select a supplier',
            },
          ]}>
            <Select
              defaultValue=""
              onChange={(value) => setFromLocationSelected(value)}
            >
              <Option value="">{("Supplier")}</Option>
              {fromlocation.map((item, index) => (
                <Option value={item.id} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item  name="to_location_id"
          rules={[
            {
              required: true,
              message: 'Please select a Warehouse',
            },
          ]}>
            <Select
              defaultValue=""
              onChange={(value) => setToLocationSelected(value)}
            >
              <Option value="">{("Warehouse")}</Option>
              {tolocation.map((item, index) => (
                <Option value={item.id} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Delivery Options">
  <Radio.Group onChange={(e) => setDeliveryOption(e.target.value)} value={deliveryOption}>
    <Radio value="Requesting">Requesting Delivery</Radio>
    <Radio value="Immediate">Immediate Pick</Radio>
  </Radio.Group>
</Form.Item>

{deliveryOption === "Requesting" && (
  <Form.Item label="Expected Delivery Date" name="expected_delivery_date">
    <DatePicker selected={edd} onChange={(date) => Setedd(date)} />
  </Form.Item>
)}
{deliveryOption === "Immediate" && (
  <Form.Item label="Immediate Pick Options">
    <Radio.Group onChange={(e) => setImmediateOption(e.target.value)} value={immediateOption}>
      <Radio value="NextDay">Next Day Delivery</Radio>
      <Radio value="ImmediatePickup">Immediate Pickup</Radio>
    </Radio.Group>
  </Form.Item>
)}

          {showAdditionalFields && (
            <>          
            <Form.Item
          label=""
          name="po_number"
          rules={[{ required: false, message: 'Please input the PO Number!' }]}
        >
          <Input placeholder="Purchase Order" />
        </Form.Item>
        
        <Form.Item
          label=""
          name="material_reference"
          rules={[{ required: false, message: 'Please input the Material Reference!' }]}
        >
          <Input placeholder={("Material Reference")} />
        </Form.Item>
        <Form.Item
          label=""
          name="shipping_company"
          rules={[{ required: false, message: 'Please input the Shipping Company!' }]}
        >
          <Input placeholder={("Shipping Company")} />
        </Form.Item>
        </>
          )}
          <Button type="text" onClick={() => setShowAdditionalFields(!showAdditionalFields)}>
            {showAdditionalFields ? ("Hide Additional Fields") : ("Show Additional Fields")}
          </Button>
        </Form>
        <br/>
        <Button className="bbutton" type="primary" onClick={submitsto}>
          {("Create Order")}
        </Button>
        <Button className="bbuttonclear"  danger onClick={clearPurchaseOrderData}>
          {("Clear")}
        </Button>
      </div>
    </div>
  );
}

export default PurchaseOrder;




