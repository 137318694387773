import React, { FC, useState } from "react";
import { Table } from "antd";
import { useGetPricealert, useGetProductwithinventory } from "../../utils/hooks";
import liquor from '../../assets/liquor.png';
import price from '../../assets/budget.png';
import { PriceAlertProps } from "../../utils/types";

const Pricemovements: FC = () => {
  const [priceAlerts, setPriceAlerts] = useState<PriceAlertProps[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [products, setProducts] = useState([]);

  useGetProductwithinventory(setProducts, setFetching);
  useGetPricealert(setPriceAlerts, setFetching);

  const filteredPriceAlerts = priceAlerts.filter((record: any) => {
    const price = Number(record.sku_code?.subunitsaleprice);
    const lowerThreshold = Number(record.lower_threshold_value);
    const upperThreshold = Number(record.upper_threshold_value);
    return price < lowerThreshold || price > upperThreshold;
  });


  const columns = [
    {
      title: () => (
        <span>
          <img src={liquor} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          SKU Name
        </span>
      ),
      dataIndex: "sku_code",
      key: "sku_code",
      render: (sku_code: any) => sku_code.name,
    },
    {
      title: () => (
        <span>
          <img src={price} alt="logo" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
          Current Price
        </span>
      ),
      dataIndex: ["sku_code", "subunitsaleprice"],
      key: ["sku_code", "subunitsaleprice"],
    },
    {
      title: "Price Range",
      key: "price_range",
      render: (record: any) => `${record.lower_threshold_value} - ${record.upper_threshold_value}`,
    },
    {
      title: "Alert",
      key: "alert",
      render: (record: any) => {
        const price = Number(record.sku_code?.subunitsaleprice);
        const lowerThreshold = Number(record.lower_threshold_value);
        const upperThreshold = Number(record.upper_threshold_value);
    
        if (!isNaN(price) && !isNaN(lowerThreshold) && price < lowerThreshold) {
          return `${record.sku_code.name} price is below the lower threshold (${lowerThreshold})`;
        } else if (!isNaN(price) && !isNaN(upperThreshold) && price > upperThreshold) {
          return `${record.sku_code.name} price is above the upper threshold (${upperThreshold})`;
        }
        return null;
      },
    }
  ];

  return (
    <div className="card">
      <div className="borderline">
        <div className="cardHeader">
          <h2 className="headContent">Price Alerts</h2>
        </div>
        <Table dataSource={filteredPriceAlerts as any} columns={columns as any} loading={fetching} />
      </div>
    </div>
  );
};

export default Pricemovements;
