import { FC, useEffect, useState } from "react";
import { Button, Table, notification, Input, Upload, Modal } from "antd";
import { AuthTokenType, DataProps } from "../utils/types";
import axios, { AxiosResponse } from "axios";
import { getAuthToken } from "../utils/functions";
import { masterdatauploadURL } from "../utils/network";

import * as XLSX from 'xlsx';

interface ContentLayoutProps {
  pageTitle: string;
  buttontitle: string;
  setModalState: (val: boolean) => void;
  dataSource: DataProps[];
  columns: DataProps[];
  fetching: boolean;
  includeFilters: boolean;
  model_name?: string;
  onAddRow: () => void; // Add onAddRow as a prop to handle row addition
  onDeleteSelected: (ids: number[]) => void,
}

// Define types for headers and rows
interface FilePreview {
  headers: string[];
  rows: any[]; // Each row can be an object or array, depending on your structure
}

const ContentLayoutnew: FC<ContentLayoutProps> = ({
  pageTitle,
  buttontitle,
  setModalState,
  dataSource,
  columns,
  fetching,
  model_name,
  includeFilters,
  onAddRow, // Use the onAddRow handler here
  onDeleteSelected,
}) => {
  const [filteredColumns, setFilteredColumns] = useState(columns);
  const [searchQuery, setSearchQuery] = useState("");

  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys: React.Key[]) => {
      setSelectedRowKeys(selectedKeys as number[]);
    },

  };
  console.log(selectedRowKeys)

  const handleDeleteSelected = () => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete the selected items?",
      onOk: () => {
        onDeleteSelected(selectedRowKeys);
        setSelectedRowKeys([]); // Clear selection after deletion
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const handleUpload = async (file: File) => {
    // Use FileReader to read the file
    const reader = new FileReader();
  
    reader.onload = async (e) => {
      const fileData = e.target?.result as string;
  
      // Parse the Excel file
      const workbook = XLSX.read(fileData, { type: 'binary' });
      const sheetName = workbook.SheetNames[0]; // Assuming the first sheet
      const sheet = workbook.Sheets[sheetName];
  
      // Convert sheet to JSON
      const fileContent = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  
      // Extract headers and rows
      const headers = fileContent[0] as string[]; // Explicitly type as string[]
      const rows = fileContent.slice(1) as any[]; // Explicitly type as any[] to handle row data
  
      // Display file content to the user before upload
      setFilePreview({ headers, rows });
  
      // Ask user for confirmation via a modal
      Modal.confirm({
        title: 'Confirm File Upload',
        content: (
          <div>
            <h3>File Contents:</h3>
            <Table
            className="custom-table"
              dataSource={rows.map((row, index) => ({ key: index, ...row }))}
              columns={headers.map((header, index) => ({
                title: header,
                dataIndex: index.toString(),
                key: index.toString(),
              }))}
              pagination={false}
              rowKey="key"
            />
          </div>
        ),
        onOk: () => proceedWithUpload(file), // Proceed with upload if confirmed
        onCancel: () => {
          notification.info({ message: 'Upload cancelled' });
        },
      });
    };
  
    reader.readAsBinaryString(file);
  };
  
  // This state holds the preview data with the correct type
  const [filePreview, setFilePreview] = useState<FilePreview | null>(null);
  
  const proceedWithUpload = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('model', model_name || '');
  
    try {
      const headers = getAuthToken() as AuthTokenType;
      const response: AxiosResponse = await axios.post(masterdatauploadURL, formData, headers );
  
      if (response) {
        notification.success({ message: 'File uploaded successfully' });
        window.location.reload();
      } else {
        notification.error({ message: 'Failed to upload file' });
      }
    } catch (error) {
      notification.error({ message: 'Failed to upload file' });
    }
  };

  useEffect(() => {
    if (dataSource && columns && includeFilters) {
      const newColumns = columns.map((column) => {
        // Handle nested objects in data (like supplier.name or sku_code.name)
        const dataIndex = column.dataIndex as string;
        // Safely access nestedField property from column
        const nestedField = (column as any).nestedField as string | undefined;
        
        // Get unique values based on dataIndex and nestedField
        const uniqueValues = Array.from(
          new Set(
            dataSource.map((data: any) => {
              const value = data[dataIndex];
              
              // Handle objects with specified nestedField (like supplier/sku_code with nestedField="name")
              if (value && typeof value === 'object' && nestedField) {
                // Use type assertion to treat value as a record with string keys
                const objValue = value as {[key: string]: any};
                if (nestedField in objValue) {
                  return objValue[nestedField];
                }
              }
              
              // Handle objects with name property as fallback (like supplier or sku_code)
              if (value && typeof value === 'object' && 'name' in value) {
                // Use type assertion for accessing 'name'
                return (value as {name: any}).name;
              }
              
              return value;
            }).filter(value => value !== null && value !== undefined)
          )
        );
        
        // Make sure we only include values that can be properly converted to filter items
        const filters = uniqueValues
          .filter(value => value !== null && value !== undefined)
          .map((value) => ({
            text: String(value),
          value,
        }));
        
        // Create a filter function that can handle nested objects
        const onFilter = (value: any, record: any) => {
          const recordValue = record[dataIndex];
          
          // Handle objects with specified nestedField
          if (recordValue && typeof recordValue === 'object' && nestedField) {
            const objValue = recordValue as {[key: string]: any};
            if (nestedField in objValue) {
              return String(objValue[nestedField]) === String(value);
            }
          }
          
          // Handle objects with name property as fallback
          if (recordValue && typeof recordValue === 'object' && 'name' in recordValue) {
            return String((recordValue as {name: any}).name) === String(value);
          }
          
          return String(recordValue) === String(value);
        };
        
        return {
          ...column,
          filters,
          filterMode: 'menu',
          filterSearch: true, // Enable search in filter dropdown
          onFilter,
          width: column.width || 150,
        };
      });
      
      setFilteredColumns(newColumns as any);
    }
  }, [dataSource, columns, includeFilters]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  // Enhanced recursive search function that can handle nested objects
  const searchInNestedObject = (obj: any, query: string, nestedField?: string): boolean => {
    if (!obj) return false;
    
    // If it's a primitive value, convert to string and search
    if (typeof obj !== 'object') {
      return String(obj).toLowerCase().includes(query.toLowerCase());
    }
    
    // If it's an array, search in each element
    if (Array.isArray(obj)) {
      return obj.some(item => searchInNestedObject(item, query));
    }
    
    // If we have a nestedField and this is an object that might have that field
    if (nestedField && typeof obj === 'object') {
      const typedObj = obj as {[key: string]: any};
      if (nestedField in typedObj) {
        return searchInNestedObject(typedObj[nestedField], query);
      }
    }
    
    // It's an object, search in all values
    return Object.values(obj).some(value => searchInNestedObject(value, query));
  };

  // Enhanced filter function for nested objects
  const filteredData = dataSource.filter((record) => {
    if (!searchQuery.trim()) return true;
    
    // Check all fields including nested objects
    return Object.entries(record).some(([key, value]) => {
      // Skip certain fields that shouldn't be searched
      if (key === 'id' || key === 'key' || value === null || value === undefined) {
        return false;
      }
      
      // Find the column definition for this key to get nestedField if available
      const column = columns.find(col => col.dataIndex === key);
      const nestedField = column && (column as any).nestedField as string | undefined;
      
      return searchInNestedObject(value, searchQuery, nestedField);
    });
  });

  // Handle CSV Export
  const handleExportToCSV = () => {
    // Convert data to CSV format
    const csvContent = columns.map(column => {
      const title = column.title;
      return typeof title === 'function' ? `Column ${column.dataIndex}` : String(title);
    }).join('\t') + '\n' +
      filteredData.map(row => {
        return columns.map(column => {
          const dataIndex = column.dataIndex as string;
          const nestedField = (column as any).nestedField as string | undefined;
          
          if (!dataIndex) return '';
          
          const value = row[dataIndex];
          
          // Handle nested objects
          if (value && typeof value === 'object') {
            // Check for specified nested field
            if (nestedField) {
              const typedValue = value as {[key: string]: any};
              if (nestedField in typedValue) {
                return typedValue[nestedField] || '';
              }
            }
            
            // Check for name property as fallback
            if ('name' in value) {
              return (value as {name: any}).name || '';
            }
            
            // Fall back to JSON string for complex objects
            return JSON.stringify(value);
          }
          
          // Simple value
          return value !== undefined && value !== null ? String(value) : '';
        }).join('\t');
      }).join('\n');
  
    // Create a Blob object containing the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  
    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);
  
    // Create a link element
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "data.csv");
  
    // Append the link to the document body and trigger the click event
    document.body.appendChild(link);
    link.click();
  
    // Clean up by revoking the temporary URL
    window.URL.revokeObjectURL(url);
  };


  
  return (
    <>
      <div className="card">
      <div className="borderline">

      <div className="cardHeader">
      <h2 className="headContent">{pageTitle}</h2>
          </div>

          <div className="rightContent">
            <Input
            className="customInput"
              placeholder="Search..."
              onChange={handleSearch}
              style={{ marginRight: '10px', width: "200px",height: "40px", }}
            />
            <Button className="bbutton" onClick={onAddRow}>{buttontitle}</Button>
            <Button className="bbutton" onClick={handleExportToCSV}>Download</Button>
            {model_name &&
              <Upload
                beforeUpload={file => {
                  handleUpload(file);
                  return false;
                }}
                showUploadList={false}
              >
                <Button>Upload</Button>
              </Upload>
            }
             <Button
             className="bbutton"
            onClick={handleDeleteSelected}
            disabled={selectedRowKeys.length === 0}
            danger
          >
            Delete Selected
          </Button>

          </div>
          
          {/* Plus sign button positioned at the top-right */}
        <br />
        <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
        <Table
         rowSelection={rowSelection}
          className="custom-table"
          dataSource={filteredData}
          columns={filteredColumns}
          pagination={{ pageSize: 20 }}
          size="small"
          loading={fetching}
          tableLayout="fixed" // Fixed table layout
          rowKey="id"
        />
        </div>
      </div>
      </div>
    </>
  );
};

export default ContentLayoutnew;
