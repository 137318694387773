import { useState, useEffect } from "react";
import { Select, Button, Spin, Drawer, Table, DatePicker } from "antd";
import { getbudgetcomparison, getbudgetoverview, getCategory, getSupplier } from "../../utils/functions";
import { category, supplier } from "../../utils/types";
import { 
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, 
  ComposedChart, Bar 
} from "recharts";
import dayjs from "dayjs";
import { ArrowUpOutlined, ArrowDownOutlined, ArrowRightOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

const BudgetComparison = () => {
  const [level, setLevel] = useState("Vendor");
  const [term, setTerm] = useState("Weekly");
  const [entityName, setEntityName] = useState("");
  const [suppliers, setSuppliers] = useState<supplier[]>([]);
  const [categories, setCategories] = useState<category[]>([]);
  const [budgetComparison, setBudgetComparison] = useState<any[]>([]);
  const [fetching, setFetching] = useState(false);
  const [selectedSpendDetails, setSelectedSpendDetails] = useState<any[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [cursorStyle, setCursorStyle] = useState("default");
  const [dateRange, setDateRange] = useState<[string, string] | null>(null);
  const [budgetoverview, setbudgetoverview] = useState<any[]>([]);
  const [filtersApplied, setFiltersApplied] = useState(false);

  useEffect(() => {
    if (level === "Vendor") {
      getSupplier(setSuppliers, setFetching);
    } else if (level === "Category") {
      getCategory(setCategories, setFetching);
    }
  }, [level]);

  useEffect(() => {
    getbudgetoverview(setbudgetoverview, setFetching);
  }, []);

  const fetchData = () => {
    setFiltersApplied(true);
    if (level && term && entityName) {
      const validDateRange = dateRange && dateRange.length === 2 ? dateRange : undefined; 
      getbudgetcomparison(setBudgetComparison, setFetching, level, term, entityName, validDateRange);
    }
  };

  const handleBarClick = (data: any) => {
    if (data && data.spend_details) {
      setSelectedSpendDetails(data.spend_details);
      setDrawerVisible(true);
    }
  };

  const columns = [
    { title: "Supplier", dataIndex: "supplier__name", key: "supplier__name" },
    { title: "Category", dataIndex: "sku_code__category__name", key: "sku_code__category__name" },
    { title: "SKU Name", dataIndex: "sku_code__name", key: "sku_code__name" },
    { title: "Spend (USD)", dataIndex: "spend", key: "spend" },
  ];
  const overviewColumns = [
    { title: "Entity Name", dataIndex: "entity_name", key: "entity_name" },
    { title: "Term", dataIndex: "term", key: "term" },
    { title: "Budget (USD)", dataIndex: "budget", key: "budget" },
    {
      title: "Current Period Spend",
      dataIndex: "current_period_spend",
      key: "current_period_spend",
      render: (text: number, record: any) => {
        let icon = <ArrowDownOutlined style={{ color: "#52c41a" }} />; // Default: Green (under budget)
  
        if (text > record.budget) {
          icon = <ArrowUpOutlined style={{ color: "#ff4d4f" }} />; // Red (over budget)
        } else if (text === record.budget) {
          icon = <ArrowRightOutlined style={{ color: "#faad14" }} />; // Amber (equal to budget)
        }
  
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
            {text} {icon}
          </div>
        );
      },
    },
    {
      title: "Last Period Spend",
      dataIndex: "last_period_spend",
      key: "last_period_spend",
      render: (text: number, record: any) => {
        let icon = <ArrowDownOutlined style={{ color: "#52c41a" }} />; // Default: Green (under budget)
  
        if (text > record.budget) {
          icon = <ArrowUpOutlined style={{ color: "#ff4d4f" }} />; // Red (over budget)
        } else if (text === record.budget) {
          icon = <ArrowRightOutlined style={{ color: "#faad14" }} />; // Amber (equal to budget)
        }
  
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
            {text} {icon}
          </div>
        );
      },
    },
  ];

  return (
    <div className="card">
      <div className="borderline">
        {/* Header */}
        <div className="cardHeader">
          <h2 className="headContent">Expenses and Budgets</h2>
        </div>

        {/* Filters */}
        <div className="leftcontent" style={{ display: "flex", gap: "10px", alignItems: "left", marginBottom: "10px" }}>
          <RangePicker
            onChange={(dates) => {
              if (dates) {
                setDateRange([dayjs(dates[0]).format("YYYY-MM-DD"), dayjs(dates[1]).format("YYYY-MM-DD")]);
              } else {
                setDateRange(null);
              }
            }}
            className="customInput"
          />
          <Select value={level} onChange={setLevel} className="customInput">
            <Select.Option value="Vendor">Vendor</Select.Option>
            <Select.Option value="Category">Category</Select.Option>
          </Select>

          <Select value={term} onChange={setTerm} className="customInput">
            <Select.Option value="Weekly">Weekly</Select.Option>
            <Select.Option value="Monthly">Monthly</Select.Option>
            <Select.Option value="Quarterly">Quarterly</Select.Option>
            <Select.Option value="Half Yearly">Half Yearly</Select.Option>
            <Select.Option value="Yearly">Yearly</Select.Option>
          </Select>

          {level === "Vendor" && (
            <Select value={entityName} onChange={setEntityName} className="customInput">
              <Select.Option value="">Select Vendor</Select.Option>
              {suppliers.map((supplier) => (
                <Select.Option key={supplier.id} value={supplier.name}>
                  {supplier.name}
                </Select.Option>
              ))}
            </Select>
          )}

          {level === "Category" && (
            <Select value={entityName} onChange={setEntityName} className="customInput">
              <Select.Option value="">Select Category</Select.Option>
              {categories.map((category) => (
                <Select.Option key={category.id} value={category.name}>
                  {category.name}
                </Select.Option>
              ))}
            </Select>
          )}

          <Button type="primary" onClick={fetchData} className="bbutton">
            Apply
          </Button>
        </div>

        {/* Render Chart or Table Based on Filters */}
        <div className="chartContainer">
          {filtersApplied && budgetComparison.length > 0 ? (
            fetching ? (
              <Spin size="small" />
            ) : (
              <ResponsiveContainer width="100%" height={400}>
                <ComposedChart 
                  data={budgetComparison} 
                  onClick={(e) => handleBarClick(e?.activePayload?.[0]?.payload)}
                  style={{ cursor: cursorStyle }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="period_starting" tick={{ fontSize: 12 }} label={{ value: "Period", position: "insideBottom", offset: -5 }} />
                  <YAxis tick={{ fontSize: 12 }} domain={[0, (dataMax: any) => dataMax + 1000]} label={{ value: "USD", angle: -90, position: "insideLeft" }} />
                  <Tooltip />
                  <Legend align="center" wrapperStyle={{ bottom: -2 }} />
                  
                  <Bar 
                    dataKey="bill_payment" 
                    fill="#8884d8" 
                    name="Expenses" 
                    onMouseOver={() => setCursorStyle("pointer")} 
                    onMouseOut={() => setCursorStyle("default")}
                  />

                  <Line type="monotone" dataKey="budget" stroke="#82ca9d" name="Budget" />
                </ComposedChart>
              </ResponsiveContainer>
            )
          ) : (
            <Table
            className="custom-table"
              dataSource={budgetoverview}
              columns={overviewColumns}
              rowKey="entity_name"
              pagination={{ pageSize: 5 }}
            />
          )}
        </div>

        {/* Drawer for Spend Details */}
        <Drawer
          title="Expense Details"
          placement="right"
          closable
          onClose={() => setDrawerVisible(false)}
          open={drawerVisible}
          width={500}
        >
          <Table
            className="custom-table"
            dataSource={selectedSpendDetails}
            columns={columns}
            rowKey="sku_code"
            pagination={{ pageSize: 5 }}
          />
        </Drawer>
      </div>
    </div>
  );
};

export default BudgetComparison;
